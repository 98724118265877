import {LayoutRows, Loadable, SearchField, SearchToolbar, Select, Tabs} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListField} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {GlobalVendorListPurposes, GlobalVendorListStacks, GlobalVendorListVendors, MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function CmpGlobalVendorList() {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [language, setLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);

    useEffect(() => {
        (async () => {
            try {
                const gvl = await session.restCmpList.getGlobalVendorList(language);
                setGlobalVendorList(gvl);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Global Vendor List", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [language, alert, textCmpLists]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_GLOBAL_VENDOR_LIST}/>
            <MainContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textCmpLists("filter.language")}>
                            <Select
                                value={language.name}
                                options={CmpConfigLanguage.values().map((it) => ({value: it.name, label: textCmpConfigurations(`language.${it.name}`), language: it}))}
                                onChange={(option) => option && setLanguage(option.language)}
                            />
                        </SearchField>
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <Tabs
                            headers={[
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.PURPOSES}`)},
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.SPECIAL_PURPOSES}`)},
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.FEATURES}`)},
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.SPECIAL_FEATURES}`)},
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.STACKS}`)},
                                {label: textCmpLists(`gvl.field.${GlobalVendorListField.VENDORS}`)}
                            ]}
                        >
                            <GlobalVendorListPurposes purposes={globalVendorList.purposes}/>
                            <GlobalVendorListPurposes purposes={globalVendorList.specialPurposes}/>
                            <GlobalVendorListPurposes purposes={globalVendorList.features}/>
                            <GlobalVendorListPurposes purposes={globalVendorList.specialFeatures}/>
                            <GlobalVendorListStacks globalVendorList={globalVendorList}/>
                            <GlobalVendorListVendors globalVendorList={globalVendorList}/>
                        </Tabs>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CmpGlobalVendorList;
