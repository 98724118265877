import React, {FormEvent, FunctionComponent, useCallback, useEffect, useState} from "react";
import {
    Action,
    AlertSeverity,
    Box,
    BoxRadius,
    BoxSpacing,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentSpacing,
    Form,
    Formatter,
    FormatterFormat,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    IconTooltip,
    InputDate,
    InputDateType,
    InputText,
    InputTextNumber,
    InputUrl,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    SelectAutocomplete,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {SalesRevenue} from "../../../api/model/revenue/sales-revenue/SalesRevenue";
import {SalesRevenueField} from "../../../api/model/revenue/sales-revenue/SalesRevenueField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";
import {SelectStatus} from "../../snippet";
import {Status} from "../../../utils/Status";
import {SalesRevenueCostType} from "../../../api/model/revenue/sales-revenue/SalesRevenueCostType";
import {session} from "../../../api/ApiSession";
import {SalesRevenueSeatField} from "../../../api/model/revenue/sales-revenue/SalesRevenueSeatField";
import {RevenueSeatSearchQuery} from "../../../api/model/revenue/seat/RevenueSeatSearchQuery";
import {RevenueSeatField} from "../../../api/model/revenue/seat/RevenueSeatField";
import {RevenueSeat} from "../../../api/model/revenue/seat/RevenueSeat";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SalesRevenueFilters} from "../../../api/model/revenue/sales-revenue/filters/SalesRevenueFilters";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";
import moment from "moment";

type ModalCreateMonthlyRevenueProps = {
    active: boolean;
    onClose: () => void;
}

const ModalCreateMonthlyRevenue: FunctionComponent<ModalCreateMonthlyRevenueProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);
    const [isSubmitting, setSubmitting] = useState(false);

    const FORM_ID = "form-create-monthly-revenue";
    const [revenue, setRevenue] = useState<SalesRevenue>(new SalesRevenue());
    const [filters, setFilters] = useState<SalesRevenueFilters>(new SalesRevenueFilters());
    const [searchOwnerId, setSearchOwnerId] = useState<number>();
    const [seats, setSeats] = useState<RevenueSeat[]>([]);
    const [selectedSeat, setSelectedSeat] = useState<RevenueSeat>();
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<SalesRevenueField>();

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    const filters = await session.restSalesRevenue.getFilters();
                    setFilters(filters);
                    setSearchOwnerId(filters.owners.find((user) => user.id === session.userId)?.id);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("filters", e.message);
                    }
                }
            })();

            const defaultDate = Formatter.formatDate(moment().date(1).add(-1, "month"), FormatterFormat.DATE_INPUT);
            const revenue = new SalesRevenue();
            revenue.date = defaultDate;
            revenue.billing_month = defaultDate;
            revenue.invoice_urls.push("");
            setRevenue(revenue);
            setShowErrors(false);
        }
    }, [active, alert, setShowErrors]);

    const loadSeats = useCallback(async () => {
        try {
            const searchResult = await session.restSeat.search(new RevenueSeatSearchQuery({
                [SearchParamsField.SIZE]: 10000,
                [RevenueSeatField.OWNER_ID]: searchOwnerId
            }));
            setSeats(searchResult.elements);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("seats", e.message);
            }
        }
    }, [alert, searchOwnerId]);

    useEffect(() => {
        (async () => {
            await loadSeats();
        })();
    }, [loadSeats]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [SalesRevenueField.REVENUE]: revenue.status === Status.TO_VALIDATE.name && revenue.revenue <= 0
        }));
    }, [revenue.revenue, revenue.status, setErrors]);

    useEffect(() => {
        setShowErrors(false);
    }, [revenue.status, setShowErrors]);

    useEffect(() => {
        setSelectedSeat(seats.find((it) => it.id === revenue.seat_id));
    }, [seats, revenue.seat_id]);

    const handleChange = (field: SalesRevenueField, value: any) => {
        setRevenue((prevState) => new SalesRevenue({...prevState, [field]: value}));
    };

    const handleChangeSearchOwnerId = (ownerId: number) => {
        setSearchOwnerId(ownerId);
        if (ownerId && selectedSeat?.owner?.id !== ownerId) {
            handleChange(SalesRevenueField.SEAT_ID, 0);
        }
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setSubmitting(true);
        try {
            const newRevenue = await session.restSalesRevenue.create(revenue);
            navigate(newRevenue.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("monthly revenue", e.message);
            }
        } finally {
            setSubmitting(false);
            setShowErrors(false);
        }
    };

    return (
        <ModalNew active={active} onClose={onClose}>
            <ModalHeader>
                <ModalHeaderTitle title={textMonthlyRevenues("create_monthly_revenue")}/>
            </ModalHeader>
            <ModalContent>
                <Form
                    id={FORM_ID}
                    onSubmitCapture={handleSubmitCapture}
                    onSubmit={handleSubmit}
                    validationType={FormValidationType.CUSTOM}
                >
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.STATUS}`)}>
                                <SelectStatus
                                    value={revenue.status}
                                    statuses={SalesRevenue.statuses().filter((it) => it !== Status.DELETED && it !== Status.VALIDATED)}
                                    onChange={(status) => handleChange(SalesRevenueField.STATUS, status?.name)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.DATE}`)} required>
                                <InputDate
                                    value={revenue.date}
                                    onChange={(value) => handleChange(SalesRevenueField.DATE, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.BILLING_MONTH}`)}>
                                <InputDate
                                    type={InputDateType.MONTH}
                                    value={Formatter.formatDate(revenue.billing_month, FormatterFormat.MONTH_INPUT)}
                                    onChange={() => void 0}
                                    disabled
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            <FieldBlock label={textMonthlyRevenues("search.seats_by_owner")} inline>
                                <SelectAutocomplete
                                    value={searchOwnerId}
                                    options={filters.owners.map((it) => ({value: it.id, label: it.fullName}))}
                                    onChange={(option) => handleChangeSearchOwnerId(option ? +option.value : 0)}
                                    clearable
                                />
                            </FieldBlock>
                            <Box radius={BoxRadius.MD} spacing={BoxSpacing.SMALL}>
                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                    <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.SEAT}`)} required>
                                        <SelectAutocomplete
                                            value={revenue.seat_id}
                                            options={seats.map((it) => ({value: it.id, label: it.label()}))}
                                            onChange={(option) => handleChange(SalesRevenueField.SEAT_ID, option?.value)}
                                            clearable
                                        />
                                    </FieldBlock>
                                    <FormLayoutColumns>
                                        <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueSeatField.ORGANIZATION}`)}>
                                            <InputText
                                                value={selectedSeat?.organization?.name || ""}
                                                onChange={() => void 0}
                                                disabled
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueSeatField.OWNER}`)}>
                                            <InputText
                                                value={selectedSeat?.owner?.name || ""}
                                                onChange={() => void 0}
                                                disabled
                                            />
                                        </FieldBlock>
                                    </FormLayoutColumns>
                                </FormLayoutRows>
                            </Box>
                        </FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.PRODUCT}`)} required>
                                <SelectAutocomplete
                                    value={revenue.product_id}
                                    options={filters.products.map((it) => ({value: it.id, label: it.label}))}
                                    onChange={(option) => handleChange(SalesRevenueField.PRODUCT_ID, option?.value)}
                                    menuPlacement="top"
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FormLayoutRows>
                                <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                    <FieldBlock
                                        label={textMonthlyRevenues(`field.${SalesRevenueField.REVENUE}`)}
                                        required={revenue.status === Status.TO_VALIDATE.name}
                                    >
                                        <InputTextNumber
                                            value={revenue.revenue}
                                            onChange={(value) => handleChange(SalesRevenueField.REVENUE, value)}
                                            min={0}
                                        />
                                        <Select
                                            value={revenue.currency}
                                            options={SalesRevenue.currencies().map((it) => ({
                                                value: it.currency,
                                                label: it.label
                                            }))}
                                            onChange={(option) => handleChange(SalesRevenueField.CURRENCY, option?.value)}
                                        />
                                    </FieldBlock>
                                    {formValidator.isError(SalesRevenueField.REVENUE) &&
                                        <FormLayoutMessage
                                            message={t("message.error.field_required")}
                                            severity={AlertSeverity.DANGER}
                                            small
                                        />
                                    }
                                </FormLayoutRows>
                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.BIDDER_PLATFORM_NAME}`)}>
                                    <SelectAutocomplete
                                        value={revenue.bidder_platform_name}
                                        options={filters.platforms.map((it) => ({value: it, label: it}))}
                                        onChange={(option) => handleChange(SalesRevenueField.BIDDER_PLATFORM_NAME, option?.value)}
                                        menuPlacement="top"
                                    />
                                </FieldBlock>
                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.IMPRESSION}`)}>
                                    <InputTextNumber
                                        value={revenue.impression}
                                        onChange={(value) => handleChange(SalesRevenueField.IMPRESSION, value)}
                                    />
                                </FieldBlock>
                            </FormLayoutRows>
                            <FormLayoutRows>
                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.COST_AMOUNT}`)}>
                                    <InputTextNumber
                                        value={revenue.cost_amount}
                                        onChange={(value) => handleChange(SalesRevenueField.COST_AMOUNT, value)}
                                        min={0}
                                    />
                                    <Select
                                        value={revenue.cost_currency}
                                        options={SalesRevenue.currencies().map((it) => ({
                                            value: it.currency,
                                            label: it.label
                                        }))}
                                        onChange={(option) => handleChange(SalesRevenueField.COST_CURRENCY, option?.value)}
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textMonthlyRevenues(`field.${SalesRevenueField.COST_TYPE}.`)}
                                    required={!!revenue.cost_amount}
                                >
                                    <Select
                                        id={SalesRevenueField.COST_TYPE}
                                        value={revenue.cost_type}
                                        options={SalesRevenueCostType.values().map((it) => ({
                                            value: it,
                                            label: textMonthlyRevenues(`field.${SalesRevenueField.COST_TYPE}.${it}`)
                                        }))}
                                        onChange={(option) => handleChange(SalesRevenueField.COST_TYPE, option?.value)}
                                        menuPlacement="top"
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textMonthlyRevenues(`field.${SalesRevenueField.COST_ORGANIZATION_ID}`)}
                                    required={!!revenue.cost_amount}
                                >
                                    <SelectAutocomplete
                                        value={revenue.cost_organization_id}
                                        options={filters.organizations.map((it) => ({value: it.id, label: it.name}))}
                                        onChange={(option) => handleChange(SalesRevenueField.COST_ORGANIZATION_ID, option?.value)}
                                        menuPlacement="top"
                                        clearable
                                    />
                                </FieldBlock>
                            </FormLayoutRows>
                        </FormLayoutColumns>
                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                            {revenue.invoice_urls.map((invoiceUrl, index) =>
                                index === 0 ?
                                    <FieldBlock
                                        key={`invoice-${index.toString()}`}
                                        label={textMonthlyRevenues(`field.${SalesRevenueField.INVOICE_URLS}`)}
                                        actions={(
                                            !!revenue.invoice_urls[revenue.invoice_urls.length - 1] ?
                                                <ButtonLink
                                                    icon={{name: "add_circle"}}
                                                    onClick={() => handleChange(SalesRevenueField.INVOICE_URLS, [...revenue.invoice_urls, ""])}
                                                    reverseUnderline
                                                >
                                                    {textCommon(Action.ADD.labelKey)}
                                                </ButtonLink> : undefined
                                        )}
                                        required={revenue.status === Status.TO_VALIDATE.name}
                                    >
                                        <InputUrl
                                            value={invoiceUrl}
                                            onChange={(value) => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.map((it, idx) => index === idx ? value : it))}
                                        />
                                    </FieldBlock> :
                                    <FieldBlock key={`invoice-${index.toString()}`}>
                                        <InputUrl
                                            value={invoiceUrl}
                                            onChange={(value) => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.map((it, idx) => index === idx ? value : it))}
                                        />
                                        {revenue.invoice_urls.length > 1 &&
                                            <IconTooltip
                                                icon={Action.REMOVE.icon}
                                                text={textCommon(Action.REMOVE.labelKey)}
                                                onClick={() => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.filter((_, idx) => index !== idx))}
                                            />
                                        }
                                    </FieldBlock>
                            )}
                        </FormLayoutRows>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateMonthlyRevenue;
