import {
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Currency, CurrencyType} from "../../../api/model/currency/Currency";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";

export type ModalEditCurrencyRatesSegmentsProps = {
    active: boolean;
    onClose: () => void;
};

const ModalEditCurrencyRatesSegments: FunctionComponent<ModalEditCurrencyRatesSegmentsProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(true);
    const [isRefreshPrices, setRefreshPrices] = useState(false);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-edit-currency-rates-segments";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<CurrencyType>();

    useEffect(() => {
        if (!active) return;

        (async () => {
            try {
                setCurrencies(await session.restCurrency.list());
                setRefreshPrices(false);
                setShowErrors(false);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("currencies", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert, setShowErrors]);

    useEffect(() => {
        const errors: { [key in CurrencyType]?: boolean } = {};
        currencies.forEach((item) => {
            errors[item.currency] = item.exchange_rate <= 0;
        });
        setErrors(errors);
    }, [currencies, setErrors]);

    const handleChangeCurrency = (id: number, value: number) => {
        setCurrencies((prevState) => {
            const newState = [...prevState];
            return newState.map((item) => {
                if (item.id === id) {
                    const newCurrency = new Currency({...item});
                    newCurrency.exchange_rate = value;
                    return newCurrency;
                }
                return item;
            });
        });
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setSubmitting(true);
        try {
            for (let currency of currencies.filter((it) => it.currency !== Currency.EUR.currency)) {
                await session.restCurrency.update(currency);
            }
            alert.updateWithSuccess("exchange rates");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("exchange rates", e.message);
            }
        } finally {
            setShowErrors(false);
            !isRefreshPrices && setSubmitting(false);
        }

        if (isRefreshPrices) {
            try {
                await session.restSegment.refreshPrices();
                alert.updateWithSuccess("segment's prices");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("segment's prices", e.message);
                }
            } finally {
                setSubmitting(false);
            }
        }

        onClose();
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.currency_rates")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        {currencies.map((item) =>
                            <FieldBlock
                                key={item.id}
                                label={item.label}
                                content={{direction: FlexContentDirection.COLUMN}}
                                inline
                            >
                                <InputTextNumber
                                    value={item.exchange_rate}
                                    onChange={(value) => handleChangeCurrency(item.id, value)}
                                    min={0}
                                    disabled={item.currency === Currency.EUR.currency}
                                />
                                {formValidator.isError(item.currency) &&
                                    <FormLayoutMessage message={t("message.error.value_not_null")} severity={AlertSeverity.DANGER} small/>
                                }
                            </FieldBlock>
                        )}
                        <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                            <ToggleSwitch
                                name="refreshPrices"
                                checked={isRefreshPrices}
                                onChange={setRefreshPrices}
                                label={textSegments("field.refresh_prices")}
                            />
                        </RestrictedContent>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <RestrictedContent allowedTo={Authorization.CURRENCIES.update}>
                <ModalActions>
                    <ButtonLinkCancel onClick={onClose}/>
                    <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
                </ModalActions>
            </RestrictedContent>
        </ModalNew>
    );
};

export default ModalEditCurrencyRatesSegments;
