import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    SelectAutocomplete,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CategoryTaxonomy} from "../../api/model/audience/category/CategoryTaxonomy";
import {Distribution} from "../../api/model/audience/distribution/Distribution";
import {DistributionField} from "../../api/model/audience/distribution/DistributionField";
import {DISTRIBUTION_PLATFORM_TYPES} from "../../api/model/audience/distribution/DistributionPlatformType";
import {DistributionPropertiesField} from "../../api/model/audience/distribution/DistributionPropertiesField";
import {DistributionSearchParamsField, DistributionSearchQuery} from "../../api/model/audience/distribution/DistributionSearchQuery";
import {DistributionSearchResult} from "../../api/model/audience/distribution/DistributionSearchResult";
import {RevenuePlatform} from "../../api/model/revenue/RevenuePlatform";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateDistribution from "../../component/modal/distributions/ModalCreateDistribution";
import {DistributionRow, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function Distributions() {
    const alert = useAlert();
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [isComponentLoaded, setComponentLoaded] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [seatPlatforms, setSeatPlatforms] = useState<RevenuePlatform[]>([]);
    const [isShowModalCreateDistribution, setShowModalCreateDistribution] = useState(false);
    const {setSearchResult, ...search} = useSearch(Distribution, DistributionSearchQuery, DistributionSearchResult);

    useEffect(() => {
        if (!isComponentLoaded) {
            search.changeParam(SearchParamsField.STATUS, Status.ACTIVE.name);
            setComponentLoaded(true);
        }
    }, [search, isComponentLoaded]);

    useEffect(() => {
        (async () => {
            try {
                setSeatPlatforms(await session.restRevenuesImport.getPlatforms());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("seat platforms", e.message);
                }
            }
        })();
    }, [alert]);

    const loadDistributions = useCallback(async () => {
        if (!isComponentLoaded) return;
        try {
            setLoading(true);
            const result = await session.restDistribution.search(search.searchQuery);
            setSearchResult(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("distributions", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert, isComponentLoaded, search.searchQuery, setSearchResult]);

    useEffect(() => {
        (async () => {
            await loadDistributions();
        })();
    }, [loadDistributions]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DISTRIBUTIONS}>
                <RestrictedContent allowedTo={Authorization.DISTRIBUTIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateDistribution(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            canHideFilters
                            searchBar={{placeholder: textDistributions("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                        >
                            <SearchField label={textDistributions(`field.${SearchParamsField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textDistributions(`search.${DistributionSearchParamsField.TAXONOMY}`)}>
                                <Select
                                    value={search.searchQuery.taxonomy}
                                    options={[
                                        {value: CategoryTaxonomy.SIRDATA.name, label: CategoryTaxonomy.SIRDATA.name},
                                        {value: CategoryTaxonomy.IAB.name, label: CategoryTaxonomy.IAB.name}
                                    ]}
                                    onChange={(option) => search.changeParam(DistributionSearchParamsField.TAXONOMY, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textDistributions(`search.${DistributionSearchParamsField.PLATFORM}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.platform}
                                    options={seatPlatforms.map((it) => ({value: it.name, label: it.name}))}
                                    onChange={(option) => search.changeParam(DistributionSearchParamsField.PLATFORM, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textDistributions(`search.${DistributionSearchParamsField.PLATFORM_TYPE}`)}>
                                <Select
                                    value={search.searchQuery.platform_type}
                                    options={DISTRIBUTION_PLATFORM_TYPES.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => search.changeParam(DistributionSearchParamsField.PLATFORM_TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textDistributions("field.status")},
                                        {width: 20, label: textDistributions(`field.${DistributionField.LABEL}`), sort: {field: DistributionField.LABEL}},
                                        {width: 20, label: textDistributions(`field.${DistributionField.PLATFORM_NAME}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: DistributionField.PLATFORM_NAME, reverseOrder: false}},
                                        {width: 20, label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.PLATFORM_TYPES}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textDistributions(`field.${DistributionField.LINK_COUNT}.segments`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textDistributions(`field.${DistributionField.LINK_COUNT}.categories`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((distribution: Distribution) =>
                                        <DistributionRow
                                            key={distribution.name}
                                            distribution={distribution}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateDistribution
                    active={isShowModalCreateDistribution}
                    onClose={() => setShowModalCreateDistribution(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Distributions;
