import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, Formatter, FormatterFormat, InputDate, InputDateType, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, SelectAutocomplete, Table} from "@sirdata/ui-lib";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {MainContentHeader, MainContentHeaderAction, MonthlyRevenueRow, SelectStatus} from "../../component/snippet";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {SalesRevenue} from "../../api/model/revenue/sales-revenue/SalesRevenue";
import {SalesRevenueField} from "../../api/model/revenue/sales-revenue/SalesRevenueField";
import {SalesRevenueFilters} from "../../api/model/revenue/sales-revenue/filters/SalesRevenueFilters";
import {SalesRevenueSeatField} from "../../api/model/revenue/sales-revenue/SalesRevenueSeatField";
import {SalesRevenueSearchParamsField, SalesRevenueSearchQuery} from "../../api/model/revenue/sales-revenue/SalesRevenueSearchQuery";
import {SalesRevenueSearchResult} from "../../api/model/revenue/sales-revenue/SalesRevenueSearchResult";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Authorization} from "../../api/model/account/Authorization";
import ModalCreateMonthlyRevenue from "../../component/modal/monthly-revenues/ModalCreateMonthlyRevenue";
import moment from "moment";

function MonthlyRevenues() {
    const alert = useAlert();
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);
    const [isComponentLoaded, setComponentLoaded] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateMonthlyRevenue, setShowModalCreateMonthlyRevenue] = useState(false);
    const {setSearchResult, ...search} = useSearch(SalesRevenue, SalesRevenueSearchQuery, SalesRevenueSearchResult);
    const [filters, setFilters] = useState<SalesRevenueFilters>(new SalesRevenueFilters());

    useEffect(() => {
        if (!isComponentLoaded) {
            const defaultMonth = Formatter.formatDate(moment(), FormatterFormat.MONTH_INPUT);
            search.changeParams({
                [SalesRevenueSearchParamsField.START_MONTH]: defaultMonth,
                [SalesRevenueSearchParamsField.END_MONTH]: defaultMonth
            });
            setComponentLoaded(true);
        }
    }, [search, isComponentLoaded]);

    useEffect(() => {
        (async () => {
            try {
                const filters = await session.restSalesRevenue.getFilters();
                setFilters(filters);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("filters", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            if (!isComponentLoaded) return;
            try {
                setLoading(true);
                const searchResult = await session.restSalesRevenue.search(search.searchQuery);
                setSearchResult(searchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("monthly revenues", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, isComponentLoaded, search.searchQuery, setSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.MONTHLY_REVENUES}>
                <RestrictedContent allowedTo={Authorization.SALES_REVENUES.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateMonthlyRevenue(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textMonthlyRevenues("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            onClearFilters={() => {
                                search.clearFilters();
                                setComponentLoaded(false);
                            }}
                            canHideFilters
                        >
                            <SearchField label={textMonthlyRevenues(`field.${SalesRevenueField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={SalesRevenue.statuses().filter((it) => it !== Status.DELETED)}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues("field.start_month")}>
                                <InputDate
                                    type={InputDateType.MONTH}
                                    value={search.searchQuery.start_month || ""}
                                    onChange={(value) => search.changeParams({
                                        [SalesRevenueSearchParamsField.START_MONTH]: value,
                                        [SalesRevenueSearchParamsField.END_MONTH]: value
                                    })}
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues("field.end_month")}>
                                <InputDate
                                    type={InputDateType.MONTH}
                                    min={search.searchQuery.start_month || ""}
                                    value={search.searchQuery.end_month || ""}
                                    onChange={(value) => search.changeParam(SalesRevenueSearchParamsField.END_MONTH, value)}
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues(`field.${SalesRevenueField.SEAT}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.seat_id}
                                    options={filters.seats.map((it) => ({value: it.id, label: it.label()}))}
                                    onChange={(option) => search.changeParam(SalesRevenueSearchParamsField.SEAT_ID, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues(`field.${SalesRevenueSeatField.PLATFORM}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.platform}
                                    options={filters.platforms.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.PLATFORM, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues(`field.${SalesRevenueField.PRODUCT}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.product_id}
                                    options={filters.products.map((it) => ({value: it.id, label: it.label}))}
                                    onChange={(option) => search.changeParam(SalesRevenueSearchParamsField.PRODUCT_ID, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textMonthlyRevenues(`field.${SalesRevenueSeatField.OWNER}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.owner_id}
                                    options={filters.owners.map((it) => ({value: it.id, label: it.name}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.OWNER, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    columns={[
                                        {width: 10, label: textMonthlyRevenues(`field.${SalesRevenueField.STATUS}`)},
                                        {width: 10, label: textMonthlyRevenues(`field.${SalesRevenueField.DATE}`)},
                                        {width: 10, label: textMonthlyRevenues(`field.${SalesRevenueField.SEAT_ID}`)},
                                        {width: 15, label: textMonthlyRevenues(`field.${SalesRevenueSeatField.ORGANIZATION}`)},
                                        {width: 10, label: textMonthlyRevenues(`field.${SalesRevenueSeatField.PLATFORM}`)},
                                        {width: 20, label: textMonthlyRevenues(`field.${SalesRevenueField.PRODUCT}`)},
                                        {width: 5, label: textMonthlyRevenues(`field.${SalesRevenueField.REVENUE}`)},
                                        {width: 5, label: textMonthlyRevenues(`field.${SalesRevenueField.COST_AMOUNT}`)},
                                        {width: 15, label: textMonthlyRevenues(`field.${SalesRevenueSeatField.OWNER}`)},
                                        {width: 1, label: textMonthlyRevenues(`field.${SalesRevenueField.INVOICE_URLS}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((revenue) =>
                                        <MonthlyRevenueRow
                                            key={revenue.id}
                                            revenue={revenue}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateMonthlyRevenue
                    active={isShowModalCreateMonthlyRevenue}
                    onClose={() => setShowModalCreateMonthlyRevenue(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default MonthlyRevenues;
