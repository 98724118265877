import React, {FunctionComponent, useEffect, useState} from "react";
import {Action, ContentBlock, ContentBlockAction, ContentBlockRow, ContentBlockRowElementRight, Loadable, ModalContent, ModalNew, ModalNewSize, Pagination, RadioButtons, SearchError, SearchField, SearchToolbar} from "@sirdata/ui-lib";
import {MediaCard} from "../../snippet";
import {Media} from "../../../api/model/media/Media";
import {MediaSearchQuery} from "../../../api/model/media/MediaSearchQuery";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {MediaSearchResult} from "../../../api/model/media/MediaSearchResult";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {ModalCreateMediaUiEvent} from "./ModalCreateMedia";
import {Authorization} from "../../../api/model/account/Authorization";
import {RestrictedContent} from "../../../common/component/widget";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {MediaField} from "../../../api/model/media/MediaField";
import useAlert from "../../../utils/hooks/useAlert";
import {MediaType} from "../../../api/model/media/MediaType";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type ModalSelectMediaProps = {
    active: boolean;
    onSelect: (media: Media) => void;
    onClose: () => void;
    allowedType?: MediaType;
};

const ModalSelectMedia: FunctionComponent<ModalSelectMediaProps> = ({active, onSelect, onClose, allowedType}) => {
    const alert = useAlert();
    const {t: textMedias} = useTranslation(TranslationPortalFile.MEDIAS);
    const [isLoading, setLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState<MediaSearchQuery>(new MediaSearchQuery());
    const [searchResult, setSearchResult] = useState<MediaSearchResult>(new MediaSearchResult());

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (allowedType) {
                    searchQuery.type = allowedType.name;
                }
                const newSearchResult = await session.restMedia.search(searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                        alert.failToLoad("medias", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, allowedType, searchQuery, alert]);

    const handleChangeQuery = (query: string) => {
        setSearchQuery((prevState) => new MediaSearchQuery({...prevState, query: query, page: 0}));
    };

    const handleChangePage = (newPage: number) => {
        setSearchQuery((prevState) => new MediaSearchQuery({...prevState, page: newPage - 1}));
    };

    const handleSelect = (media: Media) => {
        onSelect(media);
        onClose();
    };

    const handleClose = () => {
        onClose();
        setLoading(true);
    };

    return (
        <ModalNew
            onClose={handleClose}
            active={active}
            loading={false}
            size={ModalNewSize.MAX_WIDTH}
            animate
        >
            <ModalContent>
                <ContentBlock>
                    <SearchToolbar
                        searchBar={{placeholder: textMedias("search.placeholder"), value: searchQuery.query, onSubmit: handleChangeQuery}}
                        actions={[
                            <RestrictedContent key="action-add" allowedTo={Authorization.MEDIAS.update}>
                                <ContentBlockAction
                                    action={Action.ADD}
                                    onClick={() => UIEventManager.emit(ModalCreateMediaUiEvent, (refresh: boolean) => refresh && setSearchQuery(new MediaSearchQuery({...searchQuery})))}
                                />
                            </RestrictedContent>
                        ]}
                        canHideFilters={true}
                    >
                        {!allowedType &&
                            <SearchField label={textMedias(`field.${MediaField.TYPE}`)}>
                                <RadioButtons
                                    id={SearchParamsField.TYPE}
                                    options={[
                                        {label: textMedias("type.all"), value: ""},
                                        ...MediaType.values().map(({name}) => ({label: textMedias(`type.${name}`), value: name}))
                                    ]}
                                    value={searchQuery.type}
                                    onChange={(value) => setSearchQuery((prevState) => new MediaSearchQuery({...prevState, [SearchParamsField.TYPE]: value, page: 0}))}
                                />
                            </SearchField>
                        }
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        {!!searchResult.elements.length ?
                            <>
                                <ContentBlockRow>
                                    <ContentBlockRowElementRight>
                                        <Pagination {...searchResult.getPagination(handleChangePage)}/>
                                    </ContentBlockRowElementRight>
                                </ContentBlockRow>
                                <div className="media-cards">
                                    {searchResult.elements.map((media) =>
                                        <MediaCard
                                            key={media.id}
                                            media={media}
                                            onClick={() => handleSelect(media)}
                                        />
                                    )}
                                </div>
                                <ContentBlockRow>
                                    <ContentBlockRowElementRight>
                                        <Pagination {...searchResult.getPagination(handleChangePage)}/>
                                    </ContentBlockRowElementRight>
                                </ContentBlockRow>
                            </> :
                            <SearchError query={searchQuery.query}/>
                        }
                    </Loadable>
                </ContentBlock>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalSelectMedia;
