import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiProfile} from "../../api/model/auth/ApiProfile";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateUserProfile from "../../component/modal/authorizations/ModalCreateUserProfile";
import ModalAuthDetails from "../../component/modal/authorizations/ModalAuthDetails";
import {MainContentHeader, MainContentHeaderAction, UserProfileRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {AuthorizationType} from "../authorizations/AuthorizationsPartner";
import {ApiProfileSearchQuery} from "../../api/model/auth/ApiProfileSearchQuery";
import useSearch from "../../utils/hooks/useSearch";
import useAlert from "../../utils/hooks/useAlert";
import {ApiProfileField} from "../../api/model/auth/ApiProfileField";

function UserProfiles() {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const [isLoading, setLoading] = useState(true);
    const [userProfiles, setUserProfiles] = useState<ApiProfile[]>([]);
    const [activeUserProfile, setActiveUserProfile] = useState<ApiProfile>();
    const [isShowModalCreateUserProfile, setShowModalCreateUserProfile] = useState(false);
    const [isShowModalDetails, setShowModalDetails] = useState(false);
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(ApiProfile, ApiProfileSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                let userProfiles = await session.restUserProfile.list();
                setUserProfiles(userProfiles);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("profiles", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentUserProfiles = (userProfiles || []);
        if (search.searchQuery.query) {
            currentUserProfiles = currentUserProfiles.filter(({name}) => `${name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentUserProfiles);
    }, [userProfiles, search.searchQuery, buildSearchResult]);

    const handleAddUserProfile = () => {
        setActiveUserProfile(undefined);
        setShowModalCreateUserProfile(true);
    };

    const handleShowDetails = (profile: ApiProfile) => {
        setActiveUserProfile(profile);
        setShowModalDetails(true);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.USER_PROFILES}>
                <RestrictedContent allowedTo={Authorization.AUTHORIZATIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={handleAddUserProfile}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textAuthorizations("search.placeholder_profile"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 25, label: textAuthorizations(`field.${ApiProfileField.NAME}`), sort: {field: ApiProfileField.NAME, reverseOrder: false}},
                                        {width: 65, label: textAuthorizations(`field.${ApiProfileField.DESCRIPTION}`)},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((profile) =>
                                        <UserProfileRow key={profile.id} profile={profile} onShowDetails={handleShowDetails}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateUserProfile
                    active={isShowModalCreateUserProfile}
                    onClose={() => setShowModalCreateUserProfile(false)}
                />
                <ModalAuthDetails
                    active={isShowModalDetails}
                    authz={activeUserProfile}
                    type={AuthorizationType.USER}
                    onClose={() => {
                        setActiveUserProfile(undefined);
                        setShowModalDetails(false);
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default UserProfiles;
