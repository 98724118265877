import {Color, IconProps} from "@sirdata/ui-lib";

export class Status {
    static ACTIVE: Status = new Status("ACTIVE", Color.GREEN);
    static INACTIVE: Status = new Status("INACTIVE", Color.RED);

    static OPEN: Status = new Status("OPEN", Color.GREEN);
    static CLOSE: Status = new Status("CLOSE", Color.RED);

    static ONLINE: Status = new Status("ONLINE", Color.GREEN);
    static OFFLINE: Status = new Status("OFFLINE", Color.ORANGE);
    static DELETED: Status = new Status("DELETED", Color.RED);

    static AVAILABLE: Status = new Status("AVAILABLE", Color.GREEN);
    static DEPRECATED: Status = new Status("DEPRECATED", Color.RED);
    static UNAVAILABLE: Status = new Status("UNAVAILABLE", Color.RED);

    static PUBLIC: Status = new Status("PUBLIC", Color.GREEN);
    static PRIVATE: Status = new Status("PRIVATE", Color.ORANGE);

    static READ: Status = new Status("READ", Color.GREEN, "mark_email_read");
    static UNREAD: Status = new Status("UNREAD", Color.ORANGE, "mark_email_unread");

    static BLOCKED: Status = new Status("BLOCKED", Color.RED, "cancel");
    static SCHEDULED: Status = new Status("SCHEDULED", Color.MIDNIGHT_LIGHT, "schedule");
    static SUCCESS: Status = new Status("SUCCESS", Color.GREEN);

    static APPROVED: Status = new Status("APPROVED", Color.GREEN, "check_circle");

    static ACCEPTED: Status = new Status("ACCEPTED", Color.GREEN, "check_circle");
    static OK: Status = new Status("OK", Color.GREEN, "check_circle");
    static WAITING_VALIDATION: Status = new Status("WAITING_VALIDATION", Color.ORANGE, "check_circle");
    static LOADING_TABLE: Status = new Status("LOADING_TABLE", Color.ORANGE, "check_circle");
    static PENDING: Status = new Status("PENDING", Color.MIDNIGHT_LIGHT, "hourglass_top");
    static PROCESSING: Status = new Status("PROCESSING", Color.MIDNIGHT_LIGHT, "sync");
    static CANCELED: Status = new Status("CANCELED", Color.GREY_DARK, "cancel");
    static REJECTED: Status = new Status("REJECTED", Color.RED, "cancel");
    static ERROR: Status = new Status("ERROR", Color.RED, "error");

    static RELOADING: Status = new Status("RELOADING", Color.ORANGE, "sync");
    static WAITING_FOR_START: Status = new Status("WAITING_FOR_START", Color.MIDNIGHT_LIGHT, "hourglass_top");
    static MISCONFIGURED: Status = new Status("MISCONFIGURED", Color.ORANGE, "warning");
    static NOT_FOUND: Status = new Status("NOT_FOUND", Color.RED, "report");

    static DRAFT: Status = new Status("DRAFT", Color.GREY_DARK, "draft");
    static TO_VALIDATE: Status = new Status("TO_VALIDATE", Color.ORANGE, "hourglass_top");
    static VALIDATED: Status = new Status("VALIDATED", Color.GREEN, "check_circle");

    name: string;
    icon: IconProps;

    constructor(name: string, color: Color, iconName: string = "circle") {
        this.name = name;
        this.icon = {name: iconName, colorIcon: color};
    }

    static getActiveStatuses(): Status[] {
        return [Status.ACTIVE, Status.INACTIVE];
    };
}
