import {
    Action,
    ActionsMenu,
    Box,
    BoxProps,
    ContentBlock,
    ContentBlockAction,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    IconTooltip,
    InputNumber,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    SelectAutocomplete,
    SelectMultiple,
    Table,
    TableActionColumn,
    TableColumn,
    TableRow,
    TagStyle,
    Textarea,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CategoryTaxonomy} from "../../api/model/audience/category/CategoryTaxonomy";
import {Distribution} from "../../api/model/audience/distribution/Distribution";
import {DistributionBilling} from "../../api/model/audience/distribution/DistributionBilling";
import {DistributionBillingField} from "../../api/model/audience/distribution/DistributionBillingField";
import {DistributionContact} from "../../api/model/audience/distribution/DistributionContact";
import {DistributionContactField} from "../../api/model/audience/distribution/DistributionContactField";
import {DistributionData} from "../../api/model/audience/distribution/DistributionData";
import {DistributionDataField} from "../../api/model/audience/distribution/DistributionDataField";
import {DISTRIBUTION_DATA_LICENSE_TYPES, DistributionDataLicenseType} from "../../api/model/audience/distribution/DistributionDataLicenseType";
import {DISTRIBUTION_DATA_RATE_CARD, DistributionDataRateCard} from "../../api/model/audience/distribution/DistributionDataRateCard";
import {DISTRIBUTION_DATA_TYPES, DistributionDataType} from "../../api/model/audience/distribution/DistributionDataType";
import {DistributionField} from "../../api/model/audience/distribution/DistributionField";
import {DISTRIBUTION_METHODS, DistributionMethod} from "../../api/model/audience/distribution/DistributionMethod";
import {DISTRIBUTION_PLATFORM_TYPES, DistributionPlatformType} from "../../api/model/audience/distribution/DistributionPlatformType";
import {DistributionProperties} from "../../api/model/audience/distribution/DistributionProperties";
import {DistributionPropertiesField} from "../../api/model/audience/distribution/DistributionPropertiesField";
import {DistributionTechnicalField} from "../../api/model/audience/distribution/DistributionTechnicalField";
import {DISTRIBUTION_TECHNICAL_MATCHING_TABLES} from "../../api/model/audience/distribution/DistributionTechnicalMatchingTable";
import {Currency} from "../../api/model/currency/Currency";
import {MediaType} from "../../api/model/media/MediaType";
import {RevenuePlatform} from "../../api/model/revenue/RevenuePlatform";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {Country} from "../../common/api/model/Country";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {detectChanges} from "../../common/utils/portal";
import ModalManageDistributionContact from "../../component/modal/distributions/ModalManageDistributionContact";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {DistributionSegments, MainContentHeader, MainContentHeaderAction, MediaPicker, SelectStatus, Tag, TagTaxonomyCount} from "../../component/snippet";
import {TaxonomyTargetingType} from "../../utils/audience/TaxonomyTargetingType";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";

function DistributionsDetails() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const {id: distributionName} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-distribution";
    const [distribution, setDistribution] = useState<Distribution>(new Distribution());
    const [initDistribution, setInitDistribution] = useState<Distribution>(new Distribution());
    const [countries, setCountries] = useState<Country[]>([]);
    const [seatPlatforms, setSeatPlatforms] = useState<RevenuePlatform[]>([]);
    const [activeContact, setActiveContact] = useState<DistributionContact>();

    useEffect(() => {
        (async () => {
            try {
                setCountries(await session.restPartner.getCountries());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("countries", e.message);
                }
            }
            try {
                setSeatPlatforms(await session.restRevenuesImport.getPlatforms());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("seat platforms", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const distribution = await session.restDistribution.get(distributionName);
                setDistribution(distribution);
                setInitDistribution(new Distribution(distribution));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("distribution", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [distributionName, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(distribution, initDistribution));
    }, [distribution, initDistribution]);

    const handleChange = (field: DistributionField, value: any) => {
        setDistribution((prevState) => new Distribution({...prevState, [field]: value}));
    };

    const handleChangeProperties = (field: DistributionPropertiesField, value: any) => {
        handleChange(DistributionField.PROPERTIES, new DistributionProperties({...distribution.properties, [field]: value}));
    };

    const handleChangeSubProperties = (field: DistributionPropertiesField, subField: string, value: any) => {
        const newProperties = new DistributionProperties({...distribution.properties,
            [field]: {
                ...distribution.properties[field] as Record<string, unknown>,
                [subField]: value
            }
        });
        handleChange(DistributionField.PROPERTIES, newProperties);
    };

    const handleCopyPixelSynchro = () => {
        copy(`${distribution.properties.technical.pixel_synchro}`);
        alert.copied(textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.PIXEL_SYNCHRO}`));
    };

    const handleChangeInvoicing = (isInvoicing: boolean) => {
        const newBilling = new DistributionBilling({...distribution.properties.billing});
        newBilling.invoicing = isInvoicing;
        newBilling.type = isInvoicing ? DistributionMethod.AUTO : undefined;
        handleChangeProperties(DistributionPropertiesField.BILLING, newBilling);
    };

    const handleChangeCountries = (countries: string[]) => {
        if (countries.includes(DistributionData.ALL_COUNTRIES_VALUE)) {
            if (countries[countries.length - 1] === DistributionData.ALL_COUNTRIES_VALUE) {
                countries = [DistributionData.ALL_COUNTRIES_VALUE];
            } else {
                countries.splice(0, 1);
            }
        }
        handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.COUNTRIES, countries);
    };

    const handleAddContact = (contact: DistributionContact) => {
        if (!!contact.id) {
            setDistribution((prevState) => {
                const newState = new Distribution({...prevState});
                const index = newState.properties.contacts.findIndex((it) => it.id === contact.id);
                newState.properties.contacts[index] = contact;
                return newState;
            });
        } else {
            setDistribution((prevState) => {
                const newState = new Distribution({...prevState});
                const maxId = Math.max(...newState.properties.contacts.map((contact) => contact.id));
                contact.id = maxId + 1;
                newState.properties.contacts.push(contact);
                return newState;
            });
        }
        setActiveContact(undefined);
    };

    const handleRemoveContact = (contact: DistributionContact) => {
        setDistribution((prevState) => {
            const newState = new Distribution({...prevState});
            const index = newState.properties.contacts.findIndex((it) => it.id === contact.id);
            newState.properties.contacts.splice(index, 1);
            return newState;
        });
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restDistribution.update(distribution);
            setInitDistribution(new Distribution(distribution));
            alert.updateWithSuccess("distribution");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("distribution", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restDistribution.delete(distribution.name);
            navigate(Module.DISTRIBUTIONS.path);
            alert.deleteWithSuccess("distribution");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("distribution", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DISTRIBUTIONS} element={distribution.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.DISTRIBUTIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textDistributions("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns columns={4}>
                                                    <FieldBlock label={textDistributions("field.status")}>
                                                        <SelectStatus
                                                            value={distribution.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                            statuses={Status.getActiveStatuses()}
                                                            onChange={(status) => handleChange(DistributionField.ACTIVE, status === Status.ACTIVE)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.NAME}`)}>
                                                        <InputText
                                                            value={distribution.name}
                                                            onChange={() => {}}
                                                            disabled
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.LABEL}`)}>
                                                        <InputText
                                                            value={distribution.label}
                                                            onChange={(value) => handleChange(DistributionField.LABEL, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PLATFORM_NAME}`)}>
                                                        <SelectAutocomplete
                                                            value={distribution.platform_name}
                                                            options={seatPlatforms.map((it) => ({
                                                                label: it.name,
                                                                value: it.name
                                                            }))}
                                                            onChange={(option) => handleChange(DistributionField.PLATFORM_NAME, option?.value || "")}
                                                            clearable
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.PLATFORM_TYPES}`)}>
                                                        <SelectMultiple
                                                            values={distribution.properties.platform_types}
                                                            options={DISTRIBUTION_PLATFORM_TYPES.map((it) => ({
                                                                label: it,
                                                                value: it
                                                            }))}
                                                            onChange={(options) => handleChangeProperties(DistributionPropertiesField.PLATFORM_TYPES, options.map((it) => it.value as DistributionPlatformType))}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BUSINESS_MODEL}`)}>
                                                    <InputText
                                                        value={distribution.properties.business_model}
                                                        onChange={(value) => handleChangeProperties(DistributionPropertiesField.BUSINESS_MODEL, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DOCUMENTATION_URL}`)}>
                                                    <InputUrl
                                                        value={distribution.properties.documentation_url}
                                                        onChange={(value) => handleChangeProperties(DistributionPropertiesField.DOCUMENTATION_URL, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.COMMENT}`)}>
                                                    <Textarea
                                                        value={distribution.properties.comment}
                                                        onChange={(value) => handleChangeProperties(DistributionPropertiesField.COMMENT, value)}
                                                        rows={5}
                                                        isExpandable
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FormLayoutRows>
                                                        <FieldBlock name={DistributionField.ON_CREATION} label={textDistributions(`field.${DistributionField.ON_CREATION}`)}>
                                                            <ToggleSwitch
                                                                checked={distribution.on_creation}
                                                                onChange={(value) => handleChange(DistributionField.ON_CREATION, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock name={DistributionField.VISIBLE_TO_AUDIENCE} label={textDistributions(`field.${DistributionField.VISIBLE_TO_AUDIENCE}`)}>
                                                            <ToggleSwitch
                                                                checked={distribution.visible_to_audience}
                                                                onChange={(value) => handleChange(DistributionField.VISIBLE_TO_AUDIENCE, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FieldBlock name={DistributionField.USED_FOR_CATEGORY} label={textDistributions(`field.${DistributionField.USED_FOR_CATEGORY}`)}>
                                                            <ToggleSwitch
                                                                checked={distribution.used_for_category}
                                                                onChange={(value) => handleChange(DistributionField.USED_FOR_CATEGORY, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutRows>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.IMAGE_ID}`)}>
                                                        <MediaPicker
                                                            allowedType={MediaType.IMAGE}
                                                            mediaId={distribution.image_id}
                                                            onSelect={(media) => handleChange(DistributionField.IMAGE_ID, media?.id)}
                                                            onRemove={() => handleChange(DistributionField.IMAGE_ID, undefined)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{
                                            title: {label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.label`)},
                                            actions: [
                                                <RestrictedContent key="actions_contact" allowedTo={Authorization.DISTRIBUTIONS.update}>
                                                    <ContentBlockAction
                                                        action={Action.ADD}
                                                        onClick={() => setActiveContact(new DistributionContact())}
                                                    />
                                                </RestrictedContent>
                                            ]
                                        }}
                                    >
                                        <Table
                                            columns={[
                                                {width: 15, label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.${DistributionContactField.TYPE}`)},
                                                {width: 30, label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.name`)},
                                                {width: 30, label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.${DistributionContactField.EMAIL}`)},
                                                {width: 20, label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.${DistributionContactField.PHONE}`)},
                                                {width: 5}
                                            ]}
                                        >
                                            {distribution.properties.contacts.map((contact) =>
                                                <TableRow key={contact.id}>
                                                    <TableColumn>
                                                        <Tag label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.type_options.${contact.type}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                    </TableColumn>
                                                    <TableColumn>{contact.name}</TableColumn>
                                                    <TableColumn>{contact.email}</TableColumn>
                                                    <TableColumn>{contact.phone}</TableColumn>
                                                    <TableActionColumn>
                                                        <RestrictedContent allowedTo={Authorization.DISTRIBUTIONS.update}>
                                                            <ActionsMenu
                                                                iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                                items={[
                                                                    {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveContact(contact)},
                                                                    {label: textCommon(Action.REMOVE.labelKey), onClick: () => handleRemoveContact(contact), critical: true, separator: true}
                                                                ]}
                                                            />
                                                        </RestrictedContent>
                                                    </TableActionColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{title: {label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BILLING}.label`)}}}
                                    >
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FieldBlock
                                                    name={DistributionBillingField.INVOICING}
                                                    label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BILLING}.${DistributionBillingField.INVOICING}`)}
                                                >
                                                    <ToggleSwitch
                                                        checked={distribution.properties.billing.invoicing}
                                                        onChange={handleChangeInvoicing}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BILLING}.${DistributionBillingField.TYPE}`)}>
                                                        <Select
                                                            value={distribution.properties.billing.type}
                                                            options={DISTRIBUTION_METHODS.map((it) => ({
                                                                label: textDistributions(`field.method.${it}`),
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.BILLING, DistributionBillingField.TYPE, option?.value)}
                                                            disabled={!distribution.properties.billing.invoicing}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BILLING}.${DistributionBillingField.CURRENCY}`)}>
                                                        <Select
                                                            value={distribution.properties.billing.currency}
                                                            options={Currency.values().map((it) => ({
                                                                label: it.label,
                                                                value: `${it.currency}`
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.BILLING, DistributionBillingField.CURRENCY, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.BILLING}.${DistributionBillingField.COMMENT}`)}>
                                                    <Textarea
                                                        value={distribution.properties.billing.comment}
                                                        onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.BILLING, DistributionBillingField.COMMENT, value)}
                                                        rows={5}
                                                        isExpandable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{title: {label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.label`)}}}
                                    >
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.LINK_COUNT}.label`)}>
                                                        <TagTaxonomyCount type={TaxonomyTargetingType.USER} count={distribution.link_count.segment}/>
                                                        <TagTaxonomyCount type={TaxonomyTargetingType.CONTEXTUAL} count={distribution.link_count.category}/>
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.TAXONOMIES}`)}>
                                                        <SelectMultiple
                                                            values={distribution.properties.data.taxonomies}
                                                            options={[CategoryTaxonomy.SIRDATA, CategoryTaxonomy.IAB].map((it) => ({
                                                                label: it.name,
                                                                value: it.name
                                                            }))}
                                                            onChange={(options) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.TAXONOMIES, options.map((it) => it.value))}
                                                            hideSelectedOptions
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.DISTRIBUTION_TYPES}`)}>
                                                        <SelectMultiple
                                                            values={distribution.properties.data.distribution_types}
                                                            options={DISTRIBUTION_DATA_TYPES.map((it) => ({
                                                                label: textDistributions(`field.data_type.${it}`) as DistributionDataType,
                                                                value: it
                                                            }))}
                                                            onChange={(options) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.DISTRIBUTION_TYPES, options.map((it) => it.value))}
                                                            hideSelectedOptions
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.LICENSE_TYPES}`)}>
                                                        <SelectMultiple
                                                            values={distribution.properties.data.license_types}
                                                            options={DISTRIBUTION_DATA_LICENSE_TYPES.map((it) => ({
                                                                label: textDistributions(`field.license_type.${it}`) as DistributionDataLicenseType,
                                                                value: it
                                                            }))}
                                                            onChange={(options) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.LICENSE_TYPES, options.map((it) => it.value))}
                                                            hideSelectedOptions
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock
                                                        name={DistributionDataField.UI}
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.UI}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={distribution.properties.data.ui}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.UI, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.UI_URL}`)}>
                                                        <InputUrl
                                                            value={distribution.properties.data.ui_url}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.UI_URL, value)}
                                                            disabled={!distribution.properties.data.ui}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.MAPPING_TAXONOMY}`)}>
                                                        <Select
                                                            value={distribution.properties.data.mapping_taxonomy}
                                                            options={DISTRIBUTION_METHODS.map((it) => ({
                                                                label: textDistributions(`field.method.${it}`),
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.MAPPING_TAXONOMY, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.DATA_TRANSFER}`)}>
                                                        <Select
                                                            value={distribution.properties.data.data_transfer}
                                                            options={DISTRIBUTION_METHODS.map((it) => ({
                                                                label: textDistributions(`field.method.${it}`),
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.DATA_TRANSFER, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns columns={2}>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.RATE_CARD}`)}>
                                                        <Select
                                                            value={distribution.properties.data.rate_card}
                                                            options={DISTRIBUTION_DATA_RATE_CARD.map((it) => ({
                                                                label: textDistributions(`field.${DistributionDataField.RATE_CARD}.${it}`),
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.RATE_CARD, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.RATE_CARD_CUSTOM}`)}>
                                                    <Textarea
                                                        value={distribution.properties.data.rate_card_custom}
                                                        onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.RATE_CARD_CUSTOM, value)}
                                                        disabled={distribution.properties.data.rate_card !== DistributionDataRateCard.CUSTOM}
                                                        rows={5}
                                                        isExpandable
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FieldBlock
                                                        name={DistributionDataField.USAGE_REPORT}
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.USAGE_REPORT}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={distribution.properties.data.usage_report}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.USAGE_REPORT, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.REPORT_TYPE}`)}>
                                                        <Select
                                                            value={distribution.properties.data.report_type}
                                                            options={DISTRIBUTION_METHODS.map((it) => ({
                                                                label: textDistributions(`field.method.${it}`),
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.REPORT_TYPE, option?.value)}
                                                            disabled={!distribution.properties.data.usage_report}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock
                                                        name={DistributionDataField.LOOKER_INTEGRATION}
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.LOOKER_INTEGRATION}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={distribution.properties.data.looker_integration}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.DATA, DistributionDataField.LOOKER_INTEGRATION, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.DATA}.${DistributionDataField.COUNTRIES}`)}>
                                                        <SelectMultiple
                                                            values={distribution.properties.data.countries}
                                                            options={[
                                                                {label: DistributionData.ALL_COUNTRIES_VALUE, value: DistributionData.ALL_COUNTRIES_VALUE},
                                                                ...countries.map((country) => ({
                                                                    label: country.name,
                                                                    value: country.isocode
                                                                }))
                                                            ]}
                                                            onChange={(options) => handleChangeCountries(options.map((it) => it.value as string))}
                                                            hideSelectedOptions
                                                            isExpandable
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{title: {label: textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.label`)}}}
                                    >
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.RETENTION_IN_DAYS}`)}>
                                                        <InputNumber
                                                            value={distribution.properties.technical.retention_in_days}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.RETENTION_IN_DAYS, value)}
                                                            min={0}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.PIXEL_SYNCHRO}`)}
                                                        actions={
                                                            !!distribution.properties.technical.pixel_synchro &&
                                                            <IconTooltip
                                                                icon={Action.COPY.icon}
                                                                text={textCommon(Action.COPY.labelKey)}
                                                                onClick={handleCopyPixelSynchro}
                                                            />
                                                        }
                                                    >
                                                        <InputText
                                                            value={distribution.properties.technical.pixel_synchro}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.PIXEL_SYNCHRO, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock
                                                        name={DistributionTechnicalField.DOUBLE_SYNCHRO}
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.DOUBLE_SYNCHRO}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={distribution.properties.technical.double_synchro}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.DOUBLE_SYNCHRO, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.DOUBLE_SYNCHRO_URL}`)}>
                                                        <InputUrl
                                                            value={distribution.properties.technical.double_synchro_url}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.DOUBLE_SYNCHRO_URL, value)}
                                                            disabled={!distribution.properties.technical.double_synchro}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.TRELLO_CARD_URL}`)}>
                                                    <InputUrl
                                                        value={distribution.properties.technical.trello_card_url}
                                                        onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.TRELLO_CARD_URL, value)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.MATCHING_TABLE}`)}>
                                                        <Select
                                                            value={distribution.properties.technical.matching_table}
                                                            options={DISTRIBUTION_TECHNICAL_MATCHING_TABLES.map((it) => ({
                                                                label: it,
                                                                value: it
                                                            }))}
                                                            onChange={(option) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.MATCHING_TABLE, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.USED_ID}`)}>
                                                        <InputText
                                                            value={distribution.properties.technical.used_id}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.USED_ID, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.INTEGRATION_TYPE}`)}>
                                                        <InputText
                                                            value={distribution.properties.technical.integration_type}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.INTEGRATION_TYPE, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock
                                                        name={DistributionTechnicalField.CONSENT_STRING}
                                                        label={textDistributions(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.TECHNICAL}.${DistributionTechnicalField.CONSENT_STRING}`)}
                                                    >
                                                        <ToggleSwitch
                                                            checked={distribution.properties.technical.consent_string}
                                                            onChange={(value) => handleChangeSubProperties(DistributionPropertiesField.TECHNICAL, DistributionTechnicalField.CONSENT_STRING, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                    </Form>
                    <DistributionSegments distribution={distribution}/>
                </LayoutRows>
            </MainContent>
            <ModalManageDistributionContact
                active={!!activeContact}
                initContact={activeContact}
                onSubmit={handleAddContact}
                onClose={() => setActiveContact(undefined)}
            />
            <ModalConfirmDelete
                active={isActiveDelete}
                entity="distribution"
                confirm={handleDelete}
                cancel={() => setActiveDelete(false)}
            />
        </Wrapper>
    );
}

export default DistributionsDetails;
