import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {CustomerStorageSortField} from "./CustomerStorageSortField";
import {CustomerStorageSortOrder} from "./CustomerStorageSortOrder";

export class CustomerStorageSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id?: number;

    withSearchParams(params: SearchParams): CustomerStorageSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(CustomerStorageSortField.CREATION_TS.name, true) as CustomerStorageSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            sort_by: this.sortBy,
            sort_order: this.reverseOrder ? CustomerStorageSortOrder.DESCENDANT : CustomerStorageSortOrder.ASCENDANT,
            search: this.query,
            size: this.size,
            page: this.page,
            partner_id: this.partner_id
        };
    }
}
