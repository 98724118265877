import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SalesRevenueProduct} from "../model/revenue/sales-revenue/SalesRevenueProduct";
import {SearchResult} from "../interface/SearchResult";

export class RestSalesRevenueProduct extends Rest implements RestService<SalesRevenueProduct> {
    rootPath = "/console-api/sales-revenue/sales-product";

    search(params: any): Promise<SearchResult<SalesRevenueProduct>> {
        return new Promise<SearchResult<SalesRevenueProduct>>(() => {}); // Not necessary
    }

    list(): Promise<SalesRevenueProduct[]> {
        return this._client.get(`${this.rootPath}`, SalesRevenueProduct) as Promise<SalesRevenueProduct[]>;
    }

    create(product: SalesRevenueProduct): Promise<SalesRevenueProduct> {
        return this._client.post(`${this.rootPath}`, product, SalesRevenueProduct) as Promise<SalesRevenueProduct>;
    }

    get(productId: number): Promise<SalesRevenueProduct> {
        return this._client.get(`${this.rootPath}/${productId}`, SalesRevenueProduct) as Promise<SalesRevenueProduct>;
    }

    update(product: SalesRevenueProduct): Promise<SalesRevenueProduct> {
        return this._client.put(`${this.rootPath}/${product.id}`, product, SalesRevenueProduct) as Promise<SalesRevenueProduct>;
    }

    delete(productId: number) {
        return this._client.delete(`${this.rootPath}/${productId}`);
    }
}
