import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {CmpVersion} from "../../../../api/model/cmp/version/CmpVersion";
import {onClickLink} from "../../../../utils/helper";
import {Tag} from "../../index";
import {Formatter} from "../../../../common/utils/Formatter";

type CmpVersionRowProps = {
    item: CmpVersion;
}

const CmpVersionRow: FunctionComponent<CmpVersionRowProps> = ({item}) => {
    const navigate = useNavigate();

    return (
        <TableRow onClick={(e) => onClickLink(e, item.getRoute(), navigate)}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={item.version} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatDate(item.date)}</TableColumn>
            <TableColumn><span dangerouslySetInnerHTML={{__html: item.text_fr}}/></TableColumn>
            <TableColumn><span dangerouslySetInnerHTML={{__html: item.text_en}}/></TableColumn>
        </TableRow>
    );
};

export default CmpVersionRow;
