import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";

export class SegmentLinkExternal extends ApiModel {
    segment_id: number = 0;
    map_name: string = "";
    map_label: string = "";
    value: string = "";
    active: boolean = false;
    readonly last_update: string = "";

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            segment_id: this.segment_id,
            map_name: this.map_name,
            value: this.value,
            active: this.active
        };
    }

    getStatus(): Status {
        return this.active ? Status.OPEN : Status.CLOSE;
    }
}
