import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {CustomerStorage} from "../model/customer/storage/CustomerStorage";
import {CustomerStorageSearchQuery} from "../model/customer/storage/CustomerStorageSearchQuery";
import {CustomerStorageSearchResult} from "../model/customer/storage/CustomerStorageSearchResult";

export class RestCustomerStorage extends Rest implements RestService<CustomerStorage> {
    rootPath = "/console-api/customer/storage";

    search(params: CustomerStorageSearchQuery): Promise<CustomerStorageSearchResult> {
        return this._client.get(`${this.rootPath}`, CustomerStorageSearchResult, {queryParams: params.getJsonParameters()}) as Promise<CustomerStorageSearchResult>;
    }

    get(storageId: string): Promise<CustomerStorage> {
        return this._client.get(`${this.rootPath}/${storageId}`, CustomerStorage) as Promise<CustomerStorage>;
    }

    executeJob(storage: CustomerStorage) {
        return this._client.post(`${this.rootPath}/${storage.id}/exec`);
    }

    create(storage: CustomerStorage): Promise<CustomerStorage> {
        return new Promise<CustomerStorage>(() => {});
    }

    update(storage: CustomerStorage): Promise<CustomerStorage> {
        return new Promise<CustomerStorage>(() => {});
    }

    delete(obj: any): void {
    }
}
