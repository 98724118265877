import {ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {ApiService} from "../../api/model/ApiService";
import {CustomerStorage} from "../../api/model/customer/storage/CustomerStorage";
import {CustomerStorageField} from "../../api/model/customer/storage/CustomerStorageField";
import {CustomerStorageSearchQuery} from "../../api/model/customer/storage/CustomerStorageSearchQuery";
import {CustomerStorageSortField} from "../../api/model/customer/storage/CustomerStorageSortField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {CustomerStorageRow, MainContentHeader, SelectPartner} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function CustomerStorages() {
    const {t: textCustomerStorages} = useTranslation(TranslationPortalFile.CUSTOMER_STORAGES);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);

    const {setSearchResult, ...search} = useSearch(CustomerStorage, CustomerStorageSearchQuery);

    const loadStorages = useCallback(async () => {
        try {
            setLoading(true);
            setSearchResult(await session.restCustomerStorage.search(search.searchQuery));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("storages", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            await loadStorages();
        })();
    }, [loadStorages]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CUSTOMER_STORAGES}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCustomerStorages("search.search_for_storages"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textCustomerStorages(`field.${CustomerStorageField.PARTNER_ID}`)}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                    service={ApiService.CUSTOMER}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textCustomerStorages(`field.${CustomerStorageField.STATUS}`)},
                                        {width: 10, label: textCustomerStorages(`field.${CustomerStorageField.ID}`), sort: {field: CustomerStorageSortField.ID.name}},
                                        {width: 60, label: textCustomerStorages(`field.${CustomerStorageField.NAME}`), sort: {field: CustomerStorageSortField.NAME.name}, styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 20, label: textCustomerStorages(`field.${CustomerStorageField.PARTNER_ID}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((storage: CustomerStorage) =>
                                        <CustomerStorageRow key={storage.idx} storage={storage}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CustomerStorages;
