import React, {FunctionComponent} from "react";
import {ActionsMenu, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {SalesRevenue} from "../../../../api/model/revenue/sales-revenue/SalesRevenue";
import {LabelStatus, Tag} from "../../index";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {Currency} from "../../../../api/model/currency/Currency";

type MonthlyRevenueRowProps = {
    revenue: SalesRevenue;
}

const MonthlyRevenueRow: FunctionComponent<MonthlyRevenueRowProps> = ({revenue}) => {
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={revenue.getStatus()}/>
            </TableColumn>
            <TableColumn>{Formatter.formatDate(revenue.date)}</TableColumn>
            <TableColumn>{revenue.seat.seat_id}</TableColumn>
            <TableColumn>
                {revenue.seat.organization.name}
                {revenue.seat.new_business &&
                    <Tag label={textMonthlyRevenues("status.new")} style={TagStyle.PRIMARY_RED}/>
                }
            </TableColumn>
            <TableColumn>
                <Tag label={revenue.seat.platform} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>{revenue.product.label}</TableColumn>
            <TableColumn>{Currency.formatAmount(Formatter.formatNumber(revenue.revenue), revenue.currency)}</TableColumn>
            <TableColumn>{Currency.formatAmount(Formatter.formatNumber(revenue.cost_amount), revenue.cost_currency)}</TableColumn>
            <TableColumn>{revenue.seat.owner.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {!!revenue.invoice_urls.length &&
                    <ActionsMenu
                        iconTooltip={{icon: {name: "receipt_long"}, text: textMonthlyRevenues("field.invoices")}}
                        items={revenue.invoice_urls.map((url) => (
                            {label: textMonthlyRevenues("actions.open_invoice"), onClick: () => window.open(url, "_blank")}
                        ))}
                    />
                }
            </TableColumn>
        </TableRow>
    );
};

export default MonthlyRevenueRow;
