import {Color, ContentBlock, IconTooltip, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {ConsentManagementPlatform} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatform";
import {ConsentManagementPlatformField} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatformField";
import {ConsentManagementPlatformList} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatformList";
import {ConsentManagementPlatformSearchParamsField, ConsentManagementPlatformSearchQuery} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatformSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";

function CmpConsentManagementPlatforms() {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [isLoading, setLoading] = useState(true);
    const [consentManagementPlatformList, setConsentManagementPlatformList] = useState<ConsentManagementPlatformList>(new ConsentManagementPlatformList());
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(ConsentManagementPlatform, ConsentManagementPlatformSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                const consentManagementPlatformList = await session.restCmpList.getCmpList();
                setConsentManagementPlatformList(consentManagementPlatformList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Consent Management Platforms", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, textCmpLists]);

    useEffect(() => {
        let currentConsentManagementPlatforms = (consentManagementPlatformList.cmps || []);
        if (search.searchQuery.query) {
            currentConsentManagementPlatforms = currentConsentManagementPlatforms.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.commercial !== undefined) {
            currentConsentManagementPlatforms = currentConsentManagementPlatforms.filter((it) => it.isCommercial === search.searchQuery.commercial);
        }
        buildSearchResult(currentConsentManagementPlatforms);
    }, [consentManagementPlatformList, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_CONSENT_MANAGEMENT_PLATFORMS}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCmpLists("consent-management-platform.search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textCmpLists(`consent-management-platform.field.cmp.${ConsentManagementPlatformField.IS_COMMERCIAL}`)}>
                                <Select
                                    value={search.searchQuery.commercial}
                                    options={[
                                        {value: true, label: textCmpLists("consent-management-platform.field.cmp.commercial")},
                                        {value: false, label: textCmpLists("consent-management-platform.field.cmp.non_commercial")}
                                    ]}
                                    onChange={(option) => search.changeParam(ConsentManagementPlatformSearchParamsField.COMMERCIAL, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textCmpLists(`consent-management-platform.field.cmp.${ConsentManagementPlatformField.ID}`)},
                                        {width: 75, label: textCmpLists(`consent-management-platform.field.cmp.${ConsentManagementPlatformField.NAME}`)},
                                        {width: 15, label: textCmpLists(`consent-management-platform.field.cmp.${ConsentManagementPlatformField.IS_COMMERCIAL}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((cmp) =>
                                        <TableRow key={cmp.id}>
                                            <TableColumn>{cmp.id}</TableColumn>
                                            <TableColumn>{cmp.name}</TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                <IconTooltip
                                                    icon={{name: "circle", colorIcon: cmp.isCommercial ? Color.GREEN : Color.GREY_DARK}}
                                                    text={textCmpLists(cmp.isCommercial ? "consent-management-platform.tooltip.commercial" : "consent-management-platform.tooltip.non_commercial")}
                                                />
                                            </TableColumn>
                                        </TableRow>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CmpConsentManagementPlatforms;
