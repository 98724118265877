import {ApiModel} from "../../../../common/api/model/ApiModel";

export class SalesRevenueProduct extends ApiModel {
    id: number = 0;
    label: string = "";
    name: string = "";
    activity: string = "";
    activity_type: string = "";
    category: string = "";
    active: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            label: this.label,
            name: this.name,
            activity: this.activity,
            activity_type: this.activity_type,
            category: this.category,
            active: this.active
        };
    }
}
