import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CmpWebinar} from "../../api/model/cmp/webinar/CmpWebinar";
import {CmpWebinarInfo} from "../../api/model/cmp/webinar/CmpWebinarInfo";
import {CmpWebinarSearchQuery} from "../../api/model/cmp/webinar/CmpWebinarSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Locale} from "../../common/utils/Locale";
import ModalCreateCmpWebinar from "../../component/modal/cmp-webinars/ModalCreateCmpWebinar";
import {CmpWebinarRow, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {CMP_WEBINAR_STATUSES} from "../../api/model/cmp/webinar/CmpWebinarStatus";
import {Status} from "../../utils/Status";
import useSearch from "../../utils/hooks/useSearch";
import {CmpWebinarField} from "../../api/model/cmp/webinar/CmpWebinarField";
import {CmpWebinarInfoField} from "../../api/model/cmp/webinar/CmpWebinarInfoField";
import useAlert from "../../utils/hooks/useAlert";

function CmpWebinars() {
    const {t: textCmpWebinars} = useTranslation(TranslationPortalFile.CMP_WEBINARS);
    const [isLoading, setLoading] = useState(true);

    const [cmpWebinars, setCmpWebinars] = useState<CmpWebinar[]>([]);
    const [isShowModalCreateCmpWebinar, setShowModalCreateCmpWebinar] = useState(false);

    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(CmpWebinar, CmpWebinarSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                const cmpWebinars = await session.restCmpWebinar.list();
                setCmpWebinars(cmpWebinars);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("webinars", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentCmpWebinars = (cmpWebinars || []);
        if (search.searchQuery.query) {
            currentCmpWebinars = currentCmpWebinars.filter((webinar) =>
                new CmpWebinarInfo(webinar.info?.get(Locale.FRENCH)).matchQuery(search.searchQuery.query)
                || new CmpWebinarInfo(webinar.info?.get(Locale.ENGLISH)).matchQuery(search.searchQuery.query));
        }
        if (!!search.searchQuery.status) {
            currentCmpWebinars = currentCmpWebinars.filter((it) => it.status === search.searchQuery.status);
        }
        buildSearchResult(currentCmpWebinars);
    }, [cmpWebinars, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_WEBINARS}>
                <RestrictedContent allowedTo={Authorization.CMP_WEBINARS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateCmpWebinar(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCmpWebinars("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textCmpWebinars(`field.${CmpWebinarField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={CMP_WEBINAR_STATUSES.filter((it) => it.name !== Status.DELETED.name)}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textCmpWebinars(`field.${CmpWebinarField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textCmpWebinars(`field.${CmpWebinarField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCmpWebinars(`field.${CmpWebinarField.DATE}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CmpWebinarField.DATE}},
                                        {width: 80, label: textCmpWebinars(`field.${CmpWebinarInfoField.TITLE}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) =>
                                        <CmpWebinarRow key={item.id} item={item}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateCmpWebinar active={isShowModalCreateCmpWebinar} onClose={() => setShowModalCreateCmpWebinar(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default CmpWebinars;
