import {ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {HelperDomain} from "../../api/model/helper/domain/HelperDomain";
import {HelperDomainField} from "../../api/model/helper/domain/HelperDomainField";
import {HelperDomainPartnerField} from "../../api/model/helper/domain/HelperDomainPartnerField";
import {HelperDomainSearchQuery} from "../../api/model/helper/domain/HelperDomainSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {HelperDomainRow, MainContentHeader, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import useSearch from "../../utils/hooks/useSearch";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useAlert from "../../utils/hooks/useAlert";
import {Status} from "../../utils/Status";

function HelperDomains() {
    const {t: textHelperDomains} = useTranslation(TranslationPortalFile.HELPER_DOMAINS);
    const [isLoading, setLoading] = useState(true);
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(HelperDomain, HelperDomainSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                const result = await session.restHelperDomain.search(search.searchQuery);
                setSearchResult(result);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("domains", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.HELPER_DOMAINS}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textHelperDomains("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textHelperDomains(`field.${HelperDomainField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                    columns={[
                                        {label: textHelperDomains(`field.${HelperDomainField.STATUS}`), width: 5},
                                        {label: textHelperDomains(`field.${HelperDomainField.ID}`), width: 5, styles: TableColumnStyle.ALIGN_CENTER},
                                        {label: textHelperDomains(`field.${HelperDomainField.NAME}`), width: 30},
                                        {label: textHelperDomains(`field.${HelperDomainField.DOMAIN}`), width: 25},
                                        {label: textHelperDomains(`field.${HelperDomainField.PARTNER_ID}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER},
                                        {label: textHelperDomains(`field.partner.${HelperDomainPartnerField.NAME}`), width: 25, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                    ]}
                                >
                                    {search.searchResult.elements.map((domain) =>
                                        <HelperDomainRow key={domain.id} domain={domain}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default HelperDomains;
