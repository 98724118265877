import {Rest} from "../../common/api/rest/Rest";
import {Category} from "../model/audience/category/Category";
import {CategoryGroup} from "../model/audience/category/CategoryGroup";
import {CategoryType} from "../model/audience/category/CategoryType";
import {Distribution} from "../model/audience/distribution/Distribution";
import {Segment} from "../model/audience/segment/Segment";
import {Partner} from "../model/partner/Partner";
import {User} from "../model/user/User";

export class RestList extends Rest {
    rootPath = "/console-api/list";

    getUsers(withInactive?: boolean): Promise<User[]> {
        return this._client.get(`${this.rootPath}/user${!!withInactive ? "" : "?active=true"}`, User) as Promise<User[]>;
    }

    getPartners(service: string = ""): Promise<Partner[]> {
        return this._client.get(`${this.rootPath}/partner?service=${service}`, Partner) as Promise<Partner[]>;
    }

    getCategories(type?: CategoryType): Promise<Category[]> {
        return this._client.get(`${this.rootPath}/category?for=types${type ? "&type=" + type.name : ""}`, Category) as Promise<Category[]>;
    }

    getCategoryGroups(): Promise<CategoryGroup[]> {
        return this._client.get(`${this.rootPath}/category-group`, CategoryGroup) as Promise<CategoryGroup[]>;
    }

    getDistributions(): Promise<Distribution[]> {
        return this._client.get(`${this.rootPath}/distributions`, Distribution) as Promise<Distribution[]>;
    }

    getSegments(): Promise<Segment[]> {
        return this._client.get(`${this.rootPath}/segment`, Segment) as Promise<Segment[]>;
    }

    getTaxonomyTags(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/taxonomy-tag`) as Promise<string[]>;
    }
}
