import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {MainContentHeader} from "../../component/snippet";
import {Module} from "../../utils/Module";
import {ContentBlock, LayoutRows} from "@sirdata/ui-lib";

function MonthlyRevenuesDetails() {
    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.MONTHLY_REVENUES}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <></>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default MonthlyRevenuesDetails;
