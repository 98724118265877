import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class SalesRevenueFiltersSeat extends ApiModel {
    id: number = 0;
    seat_id: string = "";
    platform: string = "";
    advertiser: string = "";
    organization: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    label(): string {
        return [
            this.organization,
            `${this.platform} / ${this.seat_id}`,
            this.advertiser
        ].filter((it) => !!it).join(" - ");
    }
}
