import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SalesRevenueSeatField} from "./SalesRevenueSeatField";
import {PartnerOrganization} from "../../partner/organization/PartnerOrganization";
import {User} from "../../user/User";

export class SalesRevenueSeat extends ApiModel {
    id: number = 0;
    seat_id: number = 0;
    platform_seat_id: string = "";
    platform: string = "";
    new_business: boolean = false;
    organization: PartnerOrganization = new PartnerOrganization();
    owner: User = new User();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObject(SalesRevenueSeatField.ORGANIZATION, PartnerOrganization);
        this.loadObject(SalesRevenueSeatField.OWNER, User);
    }
}
