import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {Status} from "../../../../../utils/Status";
import {CurrencyType} from "../../../currency/Currency";
import {DistributionLicenseField} from "./DistributionLicenseField";
import {DistributionLicenseSeat} from "./DistributionLicenseSeat";
import {DistributionLicenseSegmentLinkType} from "./DistributionLicenseSegmentLinkType";
import {ContentElement} from "../../../../interface/ContentElement";

export class DistributionLicense extends ApiModel {
    id: number = 0;
    id_seat: number = 0;
    active: boolean = true;
    external_map_name: string = "";
    segment_link_type: string = DistributionLicenseSegmentLinkType.ALL.name;
    segment_link_datatype?: string;
    segments: number[] = [];
    currency: CurrencyType = CurrencyType.EUR;
    price_cpm?: number;
    price_cpm_modifier?: number;
    price_media_cost?: number;
    custom_fields: { [key: string]: any } = {};
    readonly seat: DistributionLicenseSeat = new DistributionLicenseSeat();
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObject(DistributionLicenseField.SEAT, DistributionLicenseSeat);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            this[DistributionLicenseField.CUSTOM_FIELDS] = {};
            for (let key in o) {
                if (key === DistributionLicenseField.CUSTOM_FIELDS) {
                    if (typeof o[key] === "string") {
                        this[key] = JSON.parse(o[key]);
                    } else this[key] = o[key];
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            id_seat: this.id_seat,
            active: this.active,
            external_map_name: this.external_map_name,
            segment_link_type: this.segment_link_type,
            segment_link_datatype: this.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name ? this.segment_link_datatype : undefined,
            segments: this.segments,
            currency: this.currency,
            price_cpm: this.price_cpm,
            price_cpm_modifier: this.price_cpm_modifier,
            price_media_cost: this.price_media_cost,
            custom_fields: JSON.stringify(this.custom_fields)
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, `${this.external_map_name} / ${this.id_seat}`);
        element.status = this.getStatus();
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
