import {Action, ActionsMenu, ContentBlock, ContentBlockAction, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Partner} from "../../../api/model/partner/Partner";
import {PartnerDelegation} from "../../../api/model/partner/PartnerDelegation";
import {PartnerDelegationField} from "../../../api/model/partner/PartnerDelegationField";
import {PartnerField} from "../../../api/model/partner/PartnerField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import ModalAddPartnerDelegation from "../../modal/partners/ModalAddPartnerDelegation";
import {LabelService, Tag} from "../index";

const PartnerDelegations: FC<{ partner: Partner }> = ({partner}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [delegations, setDelegations] = useState<PartnerDelegation[]>([]);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [isShowModalAddPartnerDelegation, setShowModalAddPartnerDelegation] = useState(false);
    const [activeRemovePartnerDelegation, setActiveRemovePartnerDelegation] = useState<PartnerDelegation>();

    const loadPartnerDelegations = useCallback(async () => {
        try {
            setLoading(true);
            setDelegations(await session.restPartnerDelegation.list(partner.id));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("delegations", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert, partner.id]);

    useEffect(() => {
        (async () => {
            try {
                setPartners(await session.getPartners());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            await loadPartnerDelegations();
        })();
    }, [loadPartnerDelegations]);

    const handleRemovePartnerDelegation = async () => {
        if (!activeRemovePartnerDelegation) return;
        try {
            await session.restPartnerDelegation.delete(activeRemovePartnerDelegation);
            alert.deleteWithSuccess("delegation");
            await loadPartnerDelegations();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("delegation", e.message);
            }
        } finally {
            setActiveRemovePartnerDelegation(undefined);
        }
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textPartners("section.delegation_partners")},
                    actions: (
                        <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddPartnerDelegation(true)}/>
                        </RestrictedContent>
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 10, label: textPartners(`field.${PartnerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 35, label: textPartners(`field.delegation.${PartnerDelegationField.DELEGATED_PARTNER_ID}`)},
                        {width: 25, label: textPartners(`field.delegation.${PartnerDelegationField.SERVICE}`)},
                        {width: 25, label: textPartners(`field.delegation.${PartnerDelegationField.ACCESS_LEVEL}`)},
                        {width: 5}
                    ]}
                >
                    {delegations
                        .map((delegation) => ({delegation, partner: partners.find((it) => it.id === delegation.delegated_partner_id)}))
                        .map(({delegation, partner}) =>
                            <TableRow key={`${delegation.delegated_partner_id}_${delegation.service}`}>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                    {delegation.delegated_partner_id}
                                </TableColumn>
                                <TableColumn>
                                    {partner?.fullName}
                                </TableColumn>
                                <TableColumn>
                                    <LabelService service={delegation.service}/>
                                </TableColumn>
                                <TableColumn>
                                    <Tag label={textPartners(`${PartnerDelegationField.ACCESS_LEVEL}.${delegation.access_level}`)} style={TagStyle.DEFAULT_OCEAN}/>
                                </TableColumn>
                                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                    <IconTooltip
                                        icon={{name: "person"}}
                                        text={textPartners("actions.view_partner")}
                                        onClick={() => window.open(partner?.getRoute(), "_blank")}
                                    />
                                    <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                        <ActionsMenu
                                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                            items={[
                                                {label: textCommon(Action.REMOVE.labelKey), critical: true, separator: true, onClick: () => setActiveRemovePartnerDelegation(delegation)}
                                            ]}
                                        />
                                    </RestrictedContent>
                                </TableActionColumn>
                            </TableRow>
                        )}
                </Table>
                <ModalAddPartnerDelegation
                    active={isShowModalAddPartnerDelegation}
                    partner={partner}
                    onClose={(refresh) => {
                        setShowModalAddPartnerDelegation(false);
                        refresh && loadPartnerDelegations();
                    }}
                />
                <ModalConfirmRemove
                    active={!!activeRemovePartnerDelegation}
                    entity={`delegation of partner ${activeRemovePartnerDelegation?.delegated_partner_id}`}
                    confirm={handleRemovePartnerDelegation}
                    cancel={() => setActiveRemovePartnerDelegation(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default PartnerDelegations;
