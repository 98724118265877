import {HttpContentType} from "../../common/api/http/HttpContentType";
import {Rest} from "../../common/api/rest/Rest";
import {RevenueSeat} from "../model/revenue/seat/RevenueSeat";
import {RevenueSeatSearchQuery} from "../model/revenue/seat/RevenueSeatSearchQuery";
import {RevenueSeatSearchResult} from "../model/revenue/seat/RevenueSeatSearchResult";
import {RestService} from "../interface/RestService";

export class RestSeat extends Rest implements RestService<RevenueSeat> {
    rootPath = "/console-api/revenue/seat";

    search(params: RevenueSeatSearchQuery): Promise<RevenueSeatSearchResult> {
        return this._client.get(`${this.rootPath}`, RevenueSeatSearchResult, {queryParams: params.getJsonParameters()}) as Promise<RevenueSeatSearchResult>;
    }

    create(seat: RevenueSeat): Promise<RevenueSeat> {
        return this._client.post(`${this.rootPath}`, seat, RevenueSeat) as Promise<RevenueSeat>;
    }

    get(seatId: number): Promise<RevenueSeat> {
        return this._client.get(`${this.rootPath}/${seatId}`, RevenueSeat) as Promise<RevenueSeat>;
    }

    update(seat: RevenueSeat): Promise<RevenueSeat> {
        return this._client.put(`${this.rootPath}/${seat.id}`, seat, RevenueSeat) as Promise<RevenueSeat>;
    }

    delete(seatId: number): Promise<RevenueSeat> {
        return this._client.delete(`${this.rootPath}/${seatId}`) as Promise<RevenueSeat>;
    }

    validate(seat: RevenueSeat): Promise<RevenueSeat> {
        return this._client.patch(`${this.rootPath}/${seat.id}/approved`, undefined, RevenueSeat) as Promise<RevenueSeat>;
    }

    download(seatSearchQuery: RevenueSeatSearchQuery): Promise<Blob> {
        return this._client.get(`${this.rootPath}/download/csv`, undefined, {queryParams: seatSearchQuery.getJsonParameters(), contentType: HttpContentType.CSV}) as Promise<Blob>;
    }

    getBrands(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/brand`) as Promise<string[]>;
    }

    getAdvertisers(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/advertiser`) as Promise<string[]>;
    }

    getClientSides(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/client_side`) as Promise<string[]>;
    }

    getCountries(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/country`) as Promise<string[]>;
    }
}
