import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CategoryGroup} from "../../api/model/audience/category/CategoryGroup";
import {CategoryGroupField} from "../../api/model/audience/category/CategoryGroupField";
import {CategoryGroupSearchParamsField, CategoryGroupSearchQuery} from "../../api/model/audience/category/CategoryGroupSearchQuery";
import {CategoryGroupSearchResult} from "../../api/model/audience/category/CategoryGroupSearchResult";
import {CategoryTaxonomy} from "../../api/model/audience/category/CategoryTaxonomy";
import {CategoryTopTier} from "../../api/model/audience/category/CategoryTopTier";
import {CategoryType} from "../../api/model/audience/category/CategoryType";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {downloadCSV} from "../../common/utils/portal";
import ModalCreateCategoryGroup from "../../component/modal/categories/ModalCreateCategoryGroup";
import ModalEditCurrencyRatesCategories from "../../component/modal/categories/ModalEditCurrencyRatesCategories";
import ModalImportCategoryPanel from "../../component/modal/categories/ModalImportCategoryPanel";
import ModalPopulateCategories from "../../component/modal/categories/ModalPopulateCategories";
import {CategoryGroupRow, MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function Categories() {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingExport, setLoadingExport] = useState(false);
    const [idsWithKeywords, setIdsWithKeywords] = useState<number[]>([]);
    const [idsWithSQL, setIdsWithSQL] = useState<number[]>([]);
    const [enrichmentConfigs, setEnrichmentConfigs] = useState<Map<string, object | null>>(new Map());
    const [isShowModalCreateCategoryGroup, setShowModalCreateCategoryGroup] = useState(false);
    const [isShowModalImportCategoryPanel, setShowModalImportCategoryPanel] = useState(false);
    const [isShowModalPopulateCategories, setShowModalPopulateCategories] = useState(false);
    const [isShowModalEditCurrencyRatesCategories, setShowModalEditCurrencyRatesCategories] = useState(false);
    const {setSearchResult, ...search} = useSearch(CategoryGroup, CategoryGroupSearchQuery, CategoryGroupSearchResult);

    useEffect(() => {
        (async () => {
            try {
                setIdsWithKeywords(await session.restCategoryGroup.getWithKeywords());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories with keywords", e.message);
                }
            }

            try {
                setIdsWithSQL(await session.restCategoryGroup.getWithBigQueryRequests());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories with BigQuery requests", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const newSearchResult = await session.restCategoryGroup.search(search.searchQuery);
                newSearchResult.size = PAGE_SIZE;
                newSearchResult.page = search.searchQuery.page;
                setSearchResult(newSearchResult);

                if (newSearchResult.elements.length) {
                    const mapConfigs = await session.restCategoryGroup.getEnrichmentConfigs(newSearchResult.elements.map((it) => it.id));
                    setEnrichmentConfigs(new Map<string, object | null>(Object.entries(mapConfigs) as any));
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, alert, setSearchResult]);

    const handleDownloadSearch = async () => {
        setLoadingExport(true);
        try {
            const content = await session.restCategoryGroup.export(search.searchQuery);
            downloadCSV("categories", content);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("export categories", e.message);
            }
        } finally {
            setLoadingExport(false);
        }
    };

    const hasKeywords = (categoryGroupId: number): boolean => {
        return !!idsWithKeywords.find((id) => id === categoryGroupId);
    };

    const hasSql = (categoryGroupId: number): boolean => {
        return !!idsWithSQL.find((id) => id === categoryGroupId);
    };

    const hasConfigEnrichment = (categoryGroupId: number): boolean => {
        return enrichmentConfigs.get(categoryGroupId.toString()) !== null;
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CATEGORIES}>
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <MainContentHeaderAction action={new Action(textCategories("section.currency_rates"), {name: "currency_exchange"})} onClick={() => setShowModalEditCurrencyRatesCategories(true)}/>
                </RestrictedContent>
                <MainContentHeaderAction action={Action.DOWNLOAD} disabled={isLoadingExport} onClick={handleDownloadSearch}/>
                <RestrictedContent allowedTo={Authorization.MODELING.update}>
                    <MainContentHeaderAction
                        action={new Action(textCategories("action.import_panel"), {name: "user_attributes"})}
                        onClick={() => setShowModalImportCategoryPanel(true)}
                    />
                    <MainContentHeaderAction
                        action={new Action(textCategories("action.populate_modeling_categories"), {name: "metabolism"})}
                        onClick={() => setShowModalPopulateCategories(true)}
                    />
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateCategoryGroup(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCategories("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                            onClearFilters={search.clearFilters}
                        >
                            <SearchField label={textCategories(`field.${CategoryGroupSearchParamsField.TAXONOMY}`)}>
                                <Select
                                    value={search.searchQuery.taxonomy}
                                    options={CategoryTaxonomy.values().filter((it) => it.name !== CategoryTaxonomy.PARTNER.name).map((item) => ({value: item.name, label: textCategories(`taxonomy.${item.name}`)}))}
                                    onChange={(option) => search.changeParam(CategoryGroupSearchParamsField.TAXONOMY, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCategories(`field.category.${SearchParamsField.TYPE}`)}>
                                <Select
                                    value={search.searchQuery.type}
                                    options={CategoryType.values().map((item) => ({value: item.name, label: textCategories(`category_type.${item.name}`)}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCategories(`field.${CategoryGroupSearchParamsField.TIER1}`)}>
                                <Select
                                    value={search.searchQuery.tier1}
                                    options={CategoryTopTier.values().map((tier) => ({value: tier.name, label: tier.name}))}
                                    onChange={(option) => search.changeParam(CategoryGroupSearchParamsField.TIER1, option?.value)}
                                    disabled={!!search.searchQuery.taxonomy && search.searchQuery.taxonomy !== CategoryTaxonomy.SIRDATA.name}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textCategories(`field.${CategoryGroupField.TAXONOMY}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCategories("field.types"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textCategories(`field.${CategoryGroupField.ID}`), sort: {field: CategoryGroupField.ID, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textCategories(`field.${CategoryGroupField.ID_PARENT}`), sort: {field: CategoryGroupField.ID_PARENT, reverseOrder: false}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 65, label: textCategories(`field.${CategoryGroupField.CODE_NAME}`), sort: {field: CategoryGroupField.CODE_NAME, reverseOrder: false}},
                                        {width: 8, label: textCategories("field.settings"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 2}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((categoryGroup) =>
                                        <CategoryGroupRow
                                            key={categoryGroup.id}
                                            categoryGroup={categoryGroup}
                                            hasKeywords={hasKeywords(categoryGroup.id)}
                                            hasSql={hasSql(categoryGroup.id)}
                                            hasConfigEnrichment={hasConfigEnrichment(categoryGroup.id)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateCategoryGroup
                    active={isShowModalCreateCategoryGroup}
                    onClose={() => setShowModalCreateCategoryGroup(false)}
                />
                <ModalImportCategoryPanel active={isShowModalImportCategoryPanel} onClose={() => setShowModalImportCategoryPanel(false)}/>
                <ModalPopulateCategories
                    active={isShowModalPopulateCategories}
                    onClose={() => setShowModalPopulateCategories(false)}
                />
                <ModalEditCurrencyRatesCategories
                    active={isShowModalEditCurrencyRatesCategories}
                    onClose={() => setShowModalEditCurrencyRatesCategories(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Categories;
