import {ApiModel} from "../../common/api/model/ApiModel";
import {PaginationProps} from "@sirdata/ui-lib";

export class SearchResult<T> extends ApiModel {
    _page: number = 0;
    private _size: number = 0;
    private _total_elements: number = 0;
    private _elements: T[] = [];

    constructor(protected M: new (o?: T) => T, o?: {}) {
        super();
        this.load(o);
    }

    static buildFromList<T>(M: new () => T, elements: T[], page: number, pageSize: number): SearchResult<T> {
        return new SearchResult(M, {
            page: page,
            size: pageSize,
            total_elements: elements.length,
            elements: elements.slice(page * pageSize, (page + 1) * pageSize)
        });
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "elements") {
                    if (!o[key]) continue;
                    (o[key] as T[]).forEach((obj) => {
                        const it = new this.M(obj);
                        this.elements.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    set page(page: number) {
        this._page = page;
    }

    get page(): number {
        return this._page;
    }

    set size(size: number) {
        this._size = size;
    }

    get size(): number {
        return this._size;
    }

    get total_elements(): number {
        return this._total_elements;
    }

    set total_elements(totalElements: number) {
        this._total_elements = totalElements;
    }

    get elements(): T[] {
        return this._elements;
    }

    set elements(elements: T[]) {
        this._elements = elements;
    }

    getPagination(onChangePage: (page: number) => void): PaginationProps {
        return {
            total: this.total_elements,
            page: this.page + 1,
            pageSize: this.size,
            onClick: onChangePage
        };
    }
}
