import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {PartnerDomain} from "../model/partner/domain/PartnerDomain";
import {PartnerDomainSearchQuery} from "../model/partner/domain/PartnerDomainSearchQuery";
import {PartnerDomainSearchResult} from "../model/partner/domain/PartnerDomainSearchResult";

export class RestPartnerDomain extends Rest implements RestService<PartnerDomain> {
    rootPath = "/console-api/partner-domain";

    search(search: PartnerDomainSearchQuery): Promise<PartnerDomainSearchResult> {
        return this._client.get(`${this.rootPath}`, PartnerDomainSearchResult, {queryParams: search.getJsonParameters()}) as Promise<PartnerDomainSearchResult>;
    }

    create(domain: PartnerDomain): Promise<PartnerDomain> {
        return this._client.post(`${this.rootPath}`, domain, PartnerDomain) as Promise<PartnerDomain>;
    }

    get(domainId: number): Promise<PartnerDomain> {
        return this._client.get(`${this.rootPath}/${domainId}`, PartnerDomain) as Promise<PartnerDomain>;
    }

    update(domain: PartnerDomain): Promise<PartnerDomain> {
        return this._client.put(`${this.rootPath}/${domain.id}`, domain, PartnerDomain) as Promise<PartnerDomain>;
    }

    delete(domainId: number) {
        return this._client.delete(`${this.rootPath}/${domainId}`);
    }
}
