import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerDomain} from "../../api/model/partner/domain/PartnerDomain";
import {PartnerDomainField} from "../../api/model/partner/domain/PartnerDomainField";
import {
    PartnerDomainSearchParamsField,
    PartnerDomainSearchQuery
} from "../../api/model/partner/domain/PartnerDomainSearchQuery";
import {PartnerDomainSearchResult} from "../../api/model/partner/domain/PartnerDomainSearchResult";
import {PARTNER_DOMAIN_STATUSES} from "../../api/model/partner/domain/PartnerDomainStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalCreatePartnerDomain from "../../component/modal/partner-domains/ModalCreatePartnerDomain";
import ModalEditPartnerDomain from "../../component/modal/partner-domains/ModalEditPartnerDomain";
import {
    MainContentHeader,
    MainContentHeaderAction,
    PartnerDomainRow,
    SelectPartner,
    SelectService,
    SelectStatus
} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function PartnerDomains() {
    const {t: textPartnerDomains} = useTranslation(TranslationPortalFile.PARTNER_DOMAINS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePartnerDomain, setShowModalCreatePartnerDomain] = useState(false);
    const [activeEditPartnerDomain, setActiveEditPartnerDomain] = useState<PartnerDomain>();
    const [activeDeletePartnerDomain, setActiveDeletePartnerDomain] = useState<PartnerDomain>();
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(PartnerDomain, PartnerDomainSearchQuery, PartnerDomainSearchResult);

    const loadDomains = useCallback(async () => {
        setLoading(true);
        try {
            const result = await session.restPartnerDomain.search(search.searchQuery);
            setSearchResult(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("domains", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            await loadDomains();
        })();
    }, [loadDomains]);

    const handleDelete = async () => {
        if (!activeDeletePartnerDomain) return;
        setActiveDeletePartnerDomain(undefined);

        try {
            await session.restPartnerDomain.delete(activeDeletePartnerDomain.id);
            await loadDomains();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("domain", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.PARTNER_DOMAINS}>
                <RestrictedContent allowedTo={Authorization.PARTNER_DOMAINS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartnerDomain(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textPartnerDomains("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textPartnerDomains(`field.${SearchParamsField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={PARTNER_DOMAIN_STATUSES}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartnerDomains(`field.${PartnerDomainField.TARGET_SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.target_service}
                                    services={PartnerDomain.targetServices()}
                                    onChange={(service) => search.changeParam(PartnerDomainSearchParamsField.TARGET_SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartnerDomains(`field.${PartnerDomainField.ID_PARTNER}`)}>
                                <SelectPartner
                                    value={search.searchQuery.id_partner}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {label: textPartnerDomains(`field.${SearchParamsField.STATUS}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER},
                                        {label: textPartnerDomains(`field.${PartnerDomainField.ID}`), width: 10, sort: {field: PartnerDomainField.ID, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {label: textPartnerDomains(`field.${PartnerDomainField.DOMAIN_NAME}`), width: 30, sort: {field: PartnerDomainField.DOMAIN_NAME, reverseOrder: false}},
                                        {label: textPartnerDomains(`field.${PartnerDomainField.TARGET_SERVICE}`), width: 30, sort: {field: PartnerDomainField.TARGET_SERVICE, reverseOrder: false}},
                                        {label: textPartnerDomains(`field.${PartnerDomainField.ID_PARTNER}`), width: 15, sort: {field: PartnerDomainField.ID_PARTNER, reverseOrder: false}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5}
                                    ]}
                                >
                                    {search.searchResult.elements.map((domain) =>
                                        <PartnerDomainRow
                                            key={domain.id}
                                            domain={domain}
                                            onClick={() => setActiveEditPartnerDomain(domain)}
                                            onDelete={() => setActiveDeletePartnerDomain(domain)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartnerDomain
                    active={isShowModalCreatePartnerDomain}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerDomain(false);
                        refresh && loadDomains();
                    }}
                />
                <ModalEditPartnerDomain
                    domain={activeEditPartnerDomain}
                    onClose={(refresh) => {
                        setActiveEditPartnerDomain(undefined);
                        refresh && loadDomains();
                    }}
                />
                <ModalConfirmDelete
                    active={!!activeDeletePartnerDomain}
                    entity={`domain "${activeDeletePartnerDomain?.domain_name}"`}
                    confirm={handleDelete}
                    cancel={() => setActiveDeletePartnerDomain(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PartnerDomains;
