import {LayoutColumns, LayoutRows, Loadable, Tabs} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {CmpConfigPublisher} from "../../../api/model/cmp/config/publisher/CmpConfigPublisher";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationPartners, CmpConfigurationPublisherPurposes, CmpConfigurationPurposes, CmpConfigurationPurposesDisplay, CmpConfigurationSettingsAddOns, CmpConfigurationSettingsAdvancedSettings, CmpConfigurationSettingsAppearance, CmpConfigurationSettingsImagesAndButton} from "../index";

type CmpConfigurationSettingsProps = {
    partnerId: number;
    cmpConfig: CmpConfig;
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettings: FunctionComponent<CmpConfigurationSettingsProps> = ({partnerId, cmpConfig, settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setLoading(false);
        })();
    }, [settings]);

    const handleChangeConfigVendorList = (vendorList: CmpConfigVendorList) => {
        const newConfigSettings = new CmpConfigSettings(settings);
        newConfigSettings.vendorList = vendorList;
        onChange(newConfigSettings);
    };

    const handleChangeConfigPublisherPurposes = (publisherPurposes: CmpConfigPublisher) => {
        const newConfigSettings = new CmpConfigSettings(settings);
        newConfigSettings.publisherPurposes = publisherPurposes;
        onChange(newConfigSettings);
    };

    return (
        <Loadable loading={isLoading}>
            <Tabs
                headers={[
                    {label: textCmpConfigurations("section.appearance")},
                    {label: textCmpConfigurations("section.images_button")},
                    {label: textCmpConfigurations("section.advanced_settings")},
                    {label: textCmpConfigurations("section.add-ons")},
                    {label: textCmpConfigurations("section.partners")},
                    {label: textCmpConfigurations("section.purposes")}
                ]}
            >
                <CmpConfigurationSettingsAppearance
                    cmpConfig={cmpConfig}
                    settings={settings}
                    onChange={onChange}
                />
                <CmpConfigurationSettingsImagesAndButton
                    partnerId={partnerId}
                    cmpConfig={cmpConfig}
                    settings={settings}
                    onChange={onChange}
                />
                <CmpConfigurationSettingsAdvancedSettings
                    cmpConfig={cmpConfig}
                    settings={settings}
                    onChange={onChange}
                />
                <CmpConfigurationSettingsAddOns
                    settings={settings}
                    onChange={onChange}
                />
                <CmpConfigurationPartners
                    settings={settings}
                    onChange={handleChangeConfigVendorList}
                />
                <LayoutRows>
                    <LayoutColumns>
                        <LayoutRows>
                            <CmpConfigurationPurposes
                                partnerId={partnerId}
                                vendorList={settings.vendorList}
                                onChangeVendorList={handleChangeConfigVendorList}
                            />
                        </LayoutRows>
                        <LayoutRows>
                            <CmpConfigurationPublisherPurposes
                                publisherPurposes={settings.publisherPurposes}
                                onChangePublisherPurposes={handleChangeConfigPublisherPurposes}
                            />
                            <CmpConfigurationPurposesDisplay
                                vendorList={settings.vendorList}
                                onChange={handleChangeConfigVendorList}
                            />
                        </LayoutRows>
                    </LayoutColumns>
                </LayoutRows>
            </Tabs>
        </Loadable>
    );
};

export default CmpConfigurationSettings;
