import {IconProps, MenuItem} from "@sirdata/ui-lib";
import {Authorization} from "../api/model/account/Authorization";
import {routeParamId} from "../common/utils/constants";
import {PathHelper} from "../common/utils/PathHelper";
import {
    pathAds,
    pathApiDocumentations,
    pathAudienceDataSelections,
    pathAuthorizationsPartner,
    pathAuthorizationsUser,
    pathCategories,
    pathCategorizer,
    pathCmpConfigurations,
    pathCmpConsentManagementPlatforms,
    pathCmpGlobalVendorList,
    pathCmpGoogleACProviderList,
    pathCmpNetworks,
    pathCmpSirdataList,
    pathCmpTranslations,
    pathCmpVersions,
    pathCmpWebinars,
    pathCustomerCountings,
    pathCustomerOrders,
    pathCustomerStorages,
    pathDataleaks,
    pathDataleaksQuestions,
    pathDataleaksStatistics,
    pathDataleaksVersions,
    pathDeals,
    pathDistributions,
    pathDistributionsLicenses,
    pathHelperDomains,
    pathKeywords,
    pathLicenses,
    pathMedias,
    pathMonthlyRevenues,
    pathNotifications,
    pathOrganizationGroups,
    pathOrganizations,
    pathPartnerDomains,
    pathPartners,
    pathPortalSettings,
    pathPricings,
    pathRevenuesImports,
    pathSeats,
    pathSegments,
    pathSgtmContainers,
    pathTaxonomyTags,
    pathUserProfiles,
    pathUsers
} from "./constants";

export class Module {
    label: string;
    path: string;
    icon: IconProps;
    authorization?: Authorization;

    static ADS: Module = new Module("ADS", pathAds, {name: "highlight_mouse_cursor"}, Authorization.ADS);
    static API_DOCUMENTATIONS: Module = new Module("API_DOCUMENTATIONS", pathApiDocumentations, {name: "menu_book"});
    static AUDIENCE_DATA_SELECTIONS: Module = new Module("AUDIENCE_DATA_SELECTIONS", pathAudienceDataSelections, {name: "hotel_class"}, Authorization.AUDIENCE_DATA_SELECTIONS);
    static AUTHORIZATIONS_PARTNER: Module = new Module("AUTHORIZATIONS_PARTNER", pathAuthorizationsPartner, {name: "admin_panel_settings"}, Authorization.AUTHORIZATIONS);
    static AUTHORIZATIONS_USER: Module = new Module("AUTHORIZATIONS_USER", pathAuthorizationsUser, {name: "admin_panel_settings"}, Authorization.AUTHORIZATIONS);
    static CATEGORIES: Module = new Module("CATEGORIES", pathCategories, {name: "category"}, Authorization.CATEGORIES);
    static CATEGORIZER: Module = new Module("CATEGORIZER", pathCategorizer, {name: "document_scanner", outlined: true}, Authorization.CATEGORIZER);
    static CMP_CONFIGURATIONS: Module = new Module("CMP_CONFIGURATIONS", pathCmpConfigurations, {name: "verified_user"}, Authorization.CMP_CONFIGURATIONS);
    static CMP_CONSENT_MANAGEMENT_PLATFORMS: Module = new Module("CMP_CONSENT_MANAGEMENT_PLATFORMS", pathCmpConsentManagementPlatforms, {name: "list"});
    static CMP_GLOBAL_VENDOR_LIST: Module = new Module("CMP_GLOBAL_VENDOR_LIST", pathCmpGlobalVendorList, {name: "list"});
    static CMP_GOOGLE_AC_PROVIDER_LIST: Module = new Module("CMP_GOOGLE_AC_PROVIDER_LIST", pathCmpGoogleACProviderList, {name: "list"});
    static CMP_NETWORKS: Module = new Module("CMP_NETWORKS", pathCmpNetworks, {name: "list"});
    static CMP_SIRDATA_LIST: Module = new Module("CMP_SIRDATA_LIST", pathCmpSirdataList, {name: "list"}, Authorization.CMP_SIRDATA_LIST);
    static CMP_TRANSLATIONS: Module = new Module("CMP_TRANSLATIONS", pathCmpTranslations, {name: "translate"});
    static CMP_VERSIONS: Module = new Module("CMP_VERSIONS", pathCmpVersions, {name: "conversion_path"}, Authorization.CMP_VERSIONS);
    static CMP_WEBINARS: Module = new Module("CMP_WEBINARS", pathCmpWebinars, {name: "smart_display"}, Authorization.CMP_WEBINARS);
    static CUSTOMER_COUNTINGS: Module = new Module("CUSTOMER_COUNTINGS", pathCustomerCountings, {name: "group_add"}, Authorization.CUSTOMER_COUNTINGS);
    static CUSTOMER_ORDERS: Module = new Module("CUSTOMER_ORDERS", pathCustomerOrders, {name: "shopping_cart"}, Authorization.CUSTOMER_ORDERS);
    static CUSTOMER_STORAGES: Module = new Module("CUSTOMER_STORAGES", pathCustomerStorages, {name: "database", outlined: true}, Authorization.CUSTOMER_STORAGES);
    static DATALEAKS: Module = new Module("DATALEAKS", pathDataleaks, {name: "shield_lock"}, Authorization.DATALEAKS);
    static DATALEAKS_QUESTIONS: Module = new Module("DATALEAKS_QUESTIONS", pathDataleaksQuestions, {name: "quiz"}, Authorization.DATALEAKS);
    static DATALEAKS_STATISTICS: Module = new Module("DATALEAKS_STATISTICS", pathDataleaksStatistics, {name: "bar_chart"}, Authorization.DATALEAKS);
    static DATALEAKS_VERSIONS: Module = new Module("DATALEAKS_VERSIONS", pathDataleaksVersions, {name: "conversion_path"}, Authorization.DATALEAKS);
    static DEALS: Module = new Module("DEALS", pathDeals, {name: "gavel"}, Authorization.DEALS);
    static DISTRIBUTIONS: Module = new Module("DISTRIBUTIONS", pathDistributions, {name: "stream"}, Authorization.DISTRIBUTIONS);
    static DISTRIBUTIONS_LICENSES: Module = new Module("DISTRIBUTIONS_LICENSES", pathDistributionsLicenses, {name: "license"}, Authorization.DISTRIBUTIONS_LICENSES);
    static HELPER_DOMAINS: Module = new Module("HELPER_DOMAINS", pathHelperDomains, {name: "monitoring"}, Authorization.HELPER_DOMAINS);
    static KEYWORDS: Module = new Module("KEYWORDS", pathKeywords, {name: "key"}, Authorization.KEYWORDS);
    static LICENSES: Module = new Module("LICENSES", pathLicenses, {name: "license", outlined: true}, Authorization.LICENSES);
    static MEDIAS: Module = new Module("MEDIAS", pathMedias, {name: "image", outlined: true}, Authorization.MEDIAS);
    static MONTHLY_REVENUES: Module = new Module("MONTHLY_REVENUES", pathMonthlyRevenues, {name: "savings", outlined: true}, Authorization.SALES_REVENUES);
    static NOTIFICATIONS: Module = new Module("NOTIFICATIONS", pathNotifications, {name: "notifications"}, Authorization.NOTIFICATIONS);
    static ORGANIZATION_GROUPS: Module = new Module("ORGANIZATION_GROUPS", pathOrganizationGroups, {name: "apartment"}, Authorization.ORGANIZATIONS);
    static ORGANIZATIONS: Module = new Module("ORGANIZATIONS", pathOrganizations, {name: "apartment"}, Authorization.ORGANIZATIONS);
    static PARTNER_DOMAINS: Module = new Module("PARTNER_DOMAINS", pathPartnerDomains, {name: "domain"}, Authorization.PARTNER_DOMAINS);
    static PARTNERS: Module = new Module("PARTNERS", pathPartners, {name: "groups"}, Authorization.PARTNERS);
    static PORTAL_SETTINGS: Module = new Module("PORTAL_SETTINGS", pathPortalSettings, {name: "tune"}, Authorization.PORTAL_SETTINGS);
    static PRICINGS: Module = new Module("PRICINGS", pathPricings, {name: "euro"}, Authorization.PRICINGS);
    static REVENUES_IMPORTS: Module = new Module("REVENUES_IMPORTS", pathRevenuesImports, {name: "payments"}, Authorization.REVENUES_IMPORTS);
    static SEATS: Module = new Module("SEATS", pathSeats, {name: "chair"}, Authorization.SEATS);
    static SEGMENTS: Module = new Module("SEGMENTS", pathSegments, {name: "diversity_1"}, Authorization.SEGMENTS);
    static SGTM_CONTAINERS: Module = new Module("SGTM_CONTAINERS", pathSgtmContainers, {name: "dns"}, Authorization.SGTM);
    static TAXONOMY_TAGS: Module = new Module("TAXONOMY_TAGS", pathTaxonomyTags, {name: "tag"}, Authorization.TAGS);
    static USER_PROFILES: Module = new Module("USER_PROFILES", pathUserProfiles, {name: "shield_person", outlined: false}, Authorization.AUTHORIZATIONS);
    static USERS: Module = new Module("USERS", pathUsers, {name: "group"}, Authorization.USERS);

    static values(): Module[] {
        return [
            Module.ADS,
            Module.API_DOCUMENTATIONS,
            Module.AUDIENCE_DATA_SELECTIONS,
            Module.AUTHORIZATIONS_PARTNER,
            Module.AUTHORIZATIONS_USER,
            Module.CATEGORIES,
            Module.CATEGORIZER,
            Module.CMP_CONFIGURATIONS,
            Module.CMP_CONSENT_MANAGEMENT_PLATFORMS,
            Module.CMP_GLOBAL_VENDOR_LIST,
            Module.CMP_GOOGLE_AC_PROVIDER_LIST,
            Module.CMP_NETWORKS,
            Module.CMP_SIRDATA_LIST,
            Module.CMP_TRANSLATIONS,
            Module.CMP_VERSIONS,
            Module.CMP_WEBINARS,
            Module.CUSTOMER_COUNTINGS,
            Module.CUSTOMER_ORDERS,
            Module.CUSTOMER_STORAGES,
            Module.DATALEAKS,
            Module.DATALEAKS_QUESTIONS,
            Module.DATALEAKS_STATISTICS,
            Module.DATALEAKS_VERSIONS,
            Module.DEALS,
            Module.DISTRIBUTIONS,
            Module.DISTRIBUTIONS_LICENSES,
            Module.HELPER_DOMAINS,
            Module.KEYWORDS,
            Module.LICENSES,
            Module.MEDIAS,
            Module.MONTHLY_REVENUES,
            Module.NOTIFICATIONS,
            Module.ORGANIZATION_GROUPS,
            Module.ORGANIZATIONS,
            Module.PARTNER_DOMAINS,
            Module.PARTNERS,
            Module.PORTAL_SETTINGS,
            Module.PRICINGS,
            Module.REVENUES_IMPORTS,
            Module.SEATS,
            Module.SEGMENTS,
            Module.SGTM_CONTAINERS,
            Module.TAXONOMY_TAGS,
            Module.USER_PROFILES,
            Module.USERS
        ];
    }

    constructor(name: string, path: string, icon: IconProps, authorization?: Authorization) {
        this.label = name;
        this.path = path;
        this.icon = icon;
        this.authorization = authorization;
    }

    get pathWithId(): string {
        return this.path + routeParamId;
    }

    buildRoute(id: number | string): string {
        return PathHelper.buildPathWithId(this.pathWithId, id);
    }

    toMenuItem(): MenuItem {
        return {
            label: this.label,
            path: this.path,
            icon: this.icon,
            allowedTo: this.authorization?.name
        };
    }
}
