import {ContentBlock, Loadable, SearchError, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryDomain} from "../../../api/model/audience/category/panel/CategoryDomain";
import {CategoryDomainField} from "../../../api/model/audience/category/panel/CategoryDomainField";
import {CategoryDomainRow} from "../index";
import {SearchResult} from "../../../api/interface/SearchResult";
import {SearchQuery} from "../../../api/interface/SearchQuery";

type CategoryDomainsProps = {
    category: Category;
};

const CategoryDomains: FC<CategoryDomainsProps> = ({category}) => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const {t: textCategoryDomains} = useTranslation(TranslationPortalFile.CATEGORY_DOMAINS);

    const [categoryDomains, setCategoryDomains] = useState<CategoryDomain[]>([]);

    const [searchQuery, setSearchQuery] = useState<SearchQuery>(new SearchQuery({sortBy: CategoryDomainField.ID}));
    const [searchResult, setSearchResult] = useState<SearchResult<CategoryDomain>>(new SearchResult(CategoryDomain));

    useEffect(() => {
        if (!category.id) return;
        (async () => {
            try {
                const newCategoryDomains = await session.restModeling.getCategoryDomains(category);
                setCategoryDomains(newCategoryDomains);
                setSearchResult(SearchResult.buildFromList(CategoryDomain, newCategoryDomains, 0, PAGE_SIZE));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("domains", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category, alert]);

    useEffect(() => {
        let filteredDomains = (categoryDomains || []);
        if (searchQuery.query) {
            filteredDomains = filteredDomains.filter((it) => `${it.id} ${it.domain}}`.toLowerCase().includes(searchQuery.query.toLowerCase()));
        }
        filteredDomains = searchQuery.sortItems(filteredDomains);
        const result = SearchResult.buildFromList(CategoryDomain, filteredDomains, searchQuery.page, PAGE_SIZE);
        setSearchResult(result);
    }, [categoryDomains, searchQuery]);

    const handleChangeQuery = (value: string) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, query: value, page: 0}));
    };

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(CategoryDomain, categoryDomains, page - 1, PAGE_SIZE));
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock header={{title: {label: textCategoryDomains("title")}}}>
                <SearchToolbar searchBar={{placeholder: textCategoryDomains("search.placeholder"), value: searchQuery.query, onSubmit: handleChangeQuery}}/>
                {!!searchResult.elements.length ?
                    <Table
                        columns={[
                            {label: textCategoryDomains(`field.${CategoryDomainField.ID}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textCategoryDomains(`field.${CategoryDomainField.DOMAIN}`), width: 45},
                            {label: textCategoryDomains(`field.${CategoryDomainField.DEVICE_TYPE}`), width: 15, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textCategoryDomains(`field.${CategoryDomainField.POINTS}`), width: 15, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textCategoryDomains(`field.${CategoryDomainField.EXPIRATION_DAY}`), width: 15, styles: TableColumnStyle.ALIGN_CENTER}
                        ]}
                        pagination={searchResult.getPagination(handleChangePage)}
                    >
                        {searchResult.elements.map((item) =>
                            <CategoryDomainRow key={item.id} categoryDomain={item}/>
                        )}
                    </Table> :
                    <SearchError query={searchQuery.query}/>
                }
            </ContentBlock>
        </Loadable>
    );
};

export default CategoryDomains;
