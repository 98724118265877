import {FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, LayoutRows} from "@sirdata/ui-lib";
import React from "react";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {Changelog, Modules} from "../component/snippet";

function Home() {
    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass={"home"}>
                    <FlexContent
                        direction={FlexContentDirection.ROW}
                        layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}
                        spacing={FlexContentSpacing.LARGE_PLUS}
                        mobileDirection={FlexContentMobileDirection.COLUMN}
                    >
                        <LayoutRows>
                            <Modules/>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                        </LayoutRows>
                        <Changelog/>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
