import {ContentBlock, LayoutRows, Loadable, SearchError, SearchToolbar, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {session} from "../../api/ApiSession";
import {GoogleACProvider} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProvider";
import useAlert from "../../utils/hooks/useAlert";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {GoogleACProviderField} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderField";
import useSearch from "../../utils/hooks/useSearch";
import {GoogleACProviderList} from "../../api/model/cmp/list/google-ac-provider-list/GoogleACProviderList";

function CmpGoogleACProviderList() {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [isLoading, setLoading] = useState(true);
    const [googleACProviderList, setGoogleACProviderList] = useState<GoogleACProviderList>(new GoogleACProviderList());
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(GoogleACProvider);

    useEffect(() => {
        (async () => {
            try {
                const googleACProviderList = await session.restCmpList.getGoogleACProviderList();
                setGoogleACProviderList(googleACProviderList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Google AC Provider List", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, textCmpLists]);

    useEffect(() => {
        let currentProviders = (googleACProviderList.providers || []);
        if (search.searchQuery.query) {
            currentProviders = currentProviders.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentProviders);
    }, [googleACProviderList, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_GOOGLE_AC_PROVIDER_LIST}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textCmpLists("google-ac.search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textCmpLists(`google-ac.field.provider.${GoogleACProviderField.ID}`)},
                                        {width: 30, label: textCmpLists(`google-ac.field.provider.${GoogleACProviderField.NAME}`)},
                                        {width: 60, label: textCmpLists(`google-ac.field.provider.${GoogleACProviderField.POLICY_URL}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((provider) =>
                                        <TableRow key={provider.id}>
                                            <TableColumn>{provider.id}</TableColumn>
                                            <TableColumn>{provider.name}</TableColumn>
                                            <TableColumn>{provider.policyUrl}</TableColumn>
                                        </TableRow>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CmpGoogleACProviderList;
