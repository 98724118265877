import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {CustomerCountingSortField} from "./CustomerCountingSortField";
import {CustomerCountingSortOrder} from "./CustomerCountingSortOrder";

export class CustomerCountingSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id?: number;
    status?: string;

    withSearchParams(params: SearchParams): CustomerCountingSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(CustomerCountingSortField.CREATION_TS.name, true) as CustomerCountingSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            order_by: CustomerCountingSortField.getByName(this.sortBy).name,
            sort_order: this.reverseOrder ? CustomerCountingSortOrder.DESCENDANT : CustomerCountingSortOrder.ASCENDANT,
            search: this.query,
            size: this.size,
            page: this.page,
            partner_id: this.partner_id,
            status: this.status
        };
    }
}
