import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {CmpConfigLanguageToolbar, CmpLocaleItemRow, MainContentHeader} from "../../component/snippet";
import {Module} from "../../utils/Module";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import React, {useEffect, useState} from "react";
import {session} from "../../api/ApiSession";
import {Action, ContentBlock, ContentBlockAction, FlexContent, FlexContentDirection, FlexContentSpacing, GoToTop, LayoutRows, Loadable, Paragraph, Table} from "@sirdata/ui-lib";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import useAlert from "../../utils/hooks/useAlert";
import {CmpLocale} from "../../api/model/cmp/translation/CmpLocale";
import {downloadJSON} from "../../utils/file";
import {CmpConfigVersion} from "../../api/model/cmp/config/CmpConfigVersion";
import {CmpLocaleConfig} from "../../api/model/cmp/translation/CmpLocaleConfig";
import {CmpLocaleItemField} from "../../api/model/cmp/translation/CmpLocaleItemField";

function CmpTranslations() {
    const {t: textCmpTranslations} = useTranslation(TranslationPortalFile.CMP_TRANSLATIONS);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingDownload, setLoadingDownload] = useState(false);
    const [language, setLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);
    const [cmpLocale, setCmpLocale] = useState<CmpLocale>(new CmpLocale());
    const [cmpLocaleConfig, setCmpLocaleConfig] = useState<CmpLocaleConfig>(new CmpLocaleConfig());

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const cmpLocale = await session.restCmpTranslations.getLocale(CmpConfigVersion.DEFAULT, language);
                setCmpLocale(cmpLocale);

                const cmpLocaleConfig = await session.restCmpTranslations.getTemplate(CmpConfigVersion.DEFAULT);
                setCmpLocaleConfig(cmpLocaleConfig);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("locale", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [language, alert]);

    const isInvalidLocale = () => {
        return cmpLocale.isInvalid(cmpLocaleConfig);
    };

    const handleDownload = async () => {
        setLoadingDownload(true);
        try {
            downloadJSON(language.name, JSON.stringify(cmpLocale));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("download locale", e.message);
            }
        } finally {
            setLoadingDownload(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_TRANSLATIONS}/>
            <MainContent>
                <LayoutRows>
                    <Paragraph>
                        <ul>
                            {(textCmpTranslations("steps", {returnObjects: true}) as string[]).map((item) =>
                                <li key={item}><span dangerouslySetInnerHTML={{__html: item}}/></li>
                            )}
                        </ul>
                    </Paragraph>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                        <CmpConfigLanguageToolbar value={language} onChange={(newLanguage) => setLanguage(newLanguage)}/>
                    </FlexContent>
                    <Loadable loading={isLoading}>
                        {!!cmpLocale.items.length &&
                            <ContentBlock
                                header={{
                                    title: {label: textCmpTranslations("title", {language: textCmpConfigurations(`language.${language.name}`)})},
                                    actions: (
                                        <ContentBlockAction
                                            action={Action.EXPORT}
                                            onClick={handleDownload}
                                            isDisabled={isLoadingDownload || isInvalidLocale()}
                                        />
                                    )
                                }}
                            >
                                <Table
                                    columns={[
                                        {width: 20, label: textCmpTranslations(`field.${CmpLocaleItemField.NAME}`)},
                                        {width: 80, label: textCmpTranslations(`field.${CmpLocaleItemField.VALUE}`)}
                                    ]}
                                >
                                    {cmpLocale.items.map((it) => (
                                        <CmpLocaleItemRow
                                            key={it.name}
                                            item={it}
                                            configItem={cmpLocaleConfig.getItemByKey(it.name)}
                                            onChange={(value) => setCmpLocale((prevState) => new CmpLocale({...prevState, [it.name]: value}))}
                                        />
                                    ))}
                                </Table>
                            </ContentBlock>
                        }
                    </Loadable>
                </LayoutRows>
            </MainContent>
            <GoToTop/>
        </Wrapper>
    );
}

export default CmpTranslations;
