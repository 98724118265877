import {Action, ContentBlock, InputDate, InputDateType, InputText, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CustomerOrder} from "../../api/model/customer/order/CustomerOrder";
import {CUSTOMER_ORDER_STATUSES} from "../../api/model/customer/order/CustomerOrderStatus";
import {CustomerOrderSearchParamsField, CustomerOrderSearchQuery} from "../../api/model/customer/order/CustomerOrderSearchQuery";
import {CustomerOrderSearchResult} from "../../api/model/customer/order/CustomerOrderSearchResult";
import {OrderSortType} from "../../api/model/customer/order/CustomerOrderSortType";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {CustomerOrderRow, MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useSearch from "../../utils/hooks/useSearch";
import {CustomerOrderField} from "../../api/model/customer/order/CustomerOrderField";
import useAlert from "../../utils/hooks/useAlert";
import {downloadCSV} from "../../common/utils/portal";

function CustomerOrders() {
    const {t: textCustomerOrders} = useTranslation(TranslationPortalFile.CUSTOMER_ORDERS);
    const [isLoading, setLoading] = useState(true);

    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(CustomerOrder, CustomerOrderSearchQuery, CustomerOrderSearchResult);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setSearchResult(await session.restCustomerOrder.search(search.searchQuery));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                        alert.failToLoad("orders", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    const handleDownload = async () => {
        try {
            const exportSearchQuery = new CustomerOrderSearchQuery(search.searchQuery);
            exportSearchQuery.page = 0;
            exportSearchQuery.size = 100000;
            const fileContent = await session.restCustomerOrder.export(exportSearchQuery);
            downloadCSV("customer-orders", fileContent);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("download orders", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CUSTOMER_ORDERS}>
                <MainContentHeaderAction action={Action.DOWNLOAD} label={textCustomerOrders("orders")} onClick={handleDownload}/>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCustomerOrders("search.search_for_orders"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                            onClearFilters={search.clearFilters}
                        >
                            <SearchField label={textCustomerOrders(`field.${CustomerOrderField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={CUSTOMER_ORDER_STATUSES}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCustomerOrders("field.partner")}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                    service={ApiService.CUSTOMER}
                                />
                            </SearchField>
                            <SearchField label={textCustomerOrders("field.counting")}>
                                <InputText
                                    placeholder={textCustomerOrders("search.counting_id")}
                                    value={`${search.searchQuery.filter_id || ""}`}
                                    onChange={(value) => search.changeParam(CustomerOrderSearchParamsField.FILTER_ID, value)}
                                />
                            </SearchField>
                            <SearchField label={textCustomerOrders("field.storage")}>
                                <InputText
                                    placeholder={textCustomerOrders("search.storage_id")}
                                    value={`${search.searchQuery.storage_id || ""}`}
                                    onChange={(value) => search.changeParam(CustomerOrderSearchParamsField.STORAGE_ID, value)}
                                />
                            </SearchField>
                            <SearchField label={textCustomerOrders("field.start_date")}>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={search.searchQuery.start_date}
                                    onChange={(value) => search.changeParam(CustomerOrderSearchParamsField.START_DATE, value)}
                                />
                            </SearchField>
                            <SearchField label={textCustomerOrders("field.end_date")}>
                                <InputDate
                                    type={InputDateType.DATE}
                                    value={search.searchQuery.end_date}
                                    onChange={(value) => search.changeParam(CustomerOrderSearchParamsField.END_DATE, value)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textCustomerOrders(`field.${CustomerOrderField.STATUS}`)},
                                        {width: 5, label: textCustomerOrders(`field.${CustomerOrderField.ID}`), sort: {field: OrderSortType.ID}},
                                        {width: 20, label: textCustomerOrders(`field.${CustomerOrderField.COMMENT}`), styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 10, label: textCustomerOrders("field.partner"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCustomerOrders(`field.${CustomerOrderField.ORGANIZATION_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textCustomerOrders(`field.${CustomerOrderField.FILTER_ID}`), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_MEDIUM]},
                                        {width: 15, label: textCustomerOrders(`field.${CustomerOrderField.CREATION_TS}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: OrderSortType.CREATION}},
                                        {width: 10, label: textCustomerOrders("field.hash_count"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 10, label: textCustomerOrders(`field.${CustomerOrderField.COST}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((order: CustomerOrder) =>
                                        <CustomerOrderRow key={order.id} order={order}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CustomerOrders;
