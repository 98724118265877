import {ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {ApiService} from "../../api/model/ApiService";
import {CustomerCounting} from "../../api/model/customer/counting/CustomerCounting";
import {CustomerCountingField} from "../../api/model/customer/counting/CustomerCountingField";
import {CUSTOMER_STORAGE_FILTER_STATUSES} from "../../api/model/customer/counting/CustomerCountingStatus";
import {CustomerCountingSearchQuery} from "../../api/model/customer/counting/CustomerCountingSearchQuery";
import {CustomerStorageSortField} from "../../api/model/customer/storage/CustomerStorageSortField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {CustomerCountingRow, MainContentHeader, SelectPartner, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function CustomerCountings() {
    const {t: textCustomerCountings} = useTranslation(TranslationPortalFile.CUSTOMER_COUNTINGS);
    const [isLoading, setLoading] = useState(true);

    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(CustomerCounting, CustomerCountingSearchQuery);

    const loadStorageFilters = useCallback(async () => {
        try {
            setLoading(true);
            setSearchResult(await session.restCustomerCounting.search(search.searchQuery));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("countings", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            await loadStorageFilters();
        })();
    }, [loadStorageFilters]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CUSTOMER_COUNTINGS}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCustomerCountings("search.search_for_countings"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                        >
                            <SearchField label={textCustomerCountings(`field.${CustomerCountingField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={CUSTOMER_STORAGE_FILTER_STATUSES}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                />
                            </SearchField>
                            <SearchField label={textCustomerCountings(`field.${CustomerCountingField.PARTNER_ID}`)}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                    service={ApiService.CUSTOMER}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textCustomerCountings(`field.${CustomerCountingField.STATUS}`)},
                                        {width: 10, label: textCustomerCountings(`field.${CustomerCountingField.ID}`), sort: {field: CustomerStorageSortField.ID.name}},
                                        {width: 40, label: textCustomerCountings(`field.${CustomerCountingField.NAME}`), sort: {field: CustomerStorageSortField.NAME.name}, styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 10, label: textCustomerCountings(`field.${CustomerCountingField.STORAGE_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textCustomerCountings(`field.${CustomerCountingField.DIMENSION_INDEXES}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textCustomerCountings(`field.${CustomerCountingField.SEGMENT_FILTER}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((filter: CustomerCounting) =>
                                        <CustomerCountingRow key={filter.id} filter={filter}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CustomerCountings;
