import {ApiAuthorization} from "../auth/ApiAuthorization";

enum AuthorizationService {
    AUTH = "AUTH",
    CONSOLE = "CONSOLE",
}

export class Authorization {
    static ADMIN: Authorization = new Authorization("ADMIN");
    static ADS: Authorization = new Authorization("ADS");
    static AUDIENCE_DATA_SELECTIONS: Authorization = new Authorization("AUDIENCE_DATA_SELECTIONS");
    static AUTHORIZATIONS: Authorization = new Authorization("AUTHORIZATIONS");
    static BILLINGS: Authorization = new Authorization("BILLINGS");
    static CATEGORIES: Authorization = new Authorization("CATEGORIES");
    static CATEGORIZER: Authorization = new Authorization("CATEGORIZER");
    static CATEGORIZER_ADMIN: Authorization = new Authorization(this.CATEGORIZER.shortName + ".ADMIN");
    static CMP_CONFIGURATIONS: Authorization = new Authorization("CMP_CONFIGURATIONS");
    static CMP_SIRDATA_LIST: Authorization = new Authorization("CMP_SIRDATA_LIST");
    static CMP_SIRDATA_LIST_PUBLISH: Authorization = new Authorization(this.CMP_SIRDATA_LIST.shortName + ".PUBLISH");
    static CMP_VERSIONS: Authorization = new Authorization("CMP_VERSIONS");
    static CMP_WEBINARS: Authorization = new Authorization("CMP_WEBINARS");
    static CURRENCIES: Authorization = new Authorization("CURRENCIES");
    static CUSTOMER_COUNTINGS: Authorization = new Authorization("CUSTOMER_COUNTINGS");
    static CUSTOMER_ORDERS: Authorization = new Authorization("CUSTOMER_ORDERS");
    static CUSTOMER_STORAGES: Authorization = new Authorization("CUSTOMER_STORAGES");
    static DATALEAKS: Authorization = new Authorization("DATALEAKS");
    static DEALS: Authorization = new Authorization("DEALS");
    static DEV: Authorization = new Authorization("DEV");
    static DISTRIBUTIONS: Authorization = new Authorization("DISTRIBUTIONS");
    static DISTRIBUTIONS_LICENSES: Authorization = new Authorization(this.DISTRIBUTIONS.shortName + ".LICENSES");
    static HELPER_DOMAINS: Authorization = new Authorization("HELPER_DOMAINS");
    static KEYWORDS: Authorization = new Authorization("KEYWORDS");
    static LICENSES: Authorization = new Authorization("LICENSES");
    static LOG_AS_PARTNER: Authorization = new Authorization("LOG_AS_PARTNER", AuthorizationService.AUTH);
    static MEDIAS: Authorization = new Authorization("MEDIAS");
    static MODELING: Authorization = new Authorization("MODELING");
    static MODELING_ROLLBACK: Authorization = new Authorization(this.MODELING.shortName + ".ROLLBACK");
    static NOTIFICATIONS: Authorization = new Authorization("NOTIFICATIONS");
    static ORGANIZATIONS: Authorization = new Authorization("ORGANIZATIONS");
    static PARTNER_DOMAINS: Authorization = new Authorization("PARTNER_DOMAINS");
    static PARTNERS: Authorization = new Authorization("PARTNERS");
    static PARTNERS_DELETE: Authorization = new Authorization(this.PARTNERS.shortName + ".DELETE");
    static PORTAL_SETTINGS: Authorization = new Authorization("PORTAL_SETTINGS");
    static PRICINGS: Authorization = new Authorization("PRICINGS");
    static REVENUES_IMPORTS: Authorization = new Authorization("REVENUES_IMPORTS");
    static SALES_REVENUES: Authorization = new Authorization("SALES_REVENUES");
    static SALES_REVENUES_ADMIN: Authorization = new Authorization(this.SALES_REVENUES.shortName + ".ADMIN");
    static SEATS: Authorization = new Authorization("SEATS");
    static SEATS_VALIDATE: Authorization = new Authorization(this.SEATS.shortName + ".VALIDATE");
    static SEGMENTS: Authorization = new Authorization("SEGMENTS");
    static SGTM: Authorization = new Authorization("SGTM");
    static TAGS: Authorization = new Authorization("TAGS");
    static USERS: Authorization = new Authorization("USERS");

    shortName: string = "";
    service: AuthorizationService;

    private constructor(shortName: string, service: AuthorizationService = AuthorizationService.CONSOLE) {
        this.shortName = shortName;
        this.service = service;
    }

    static values(): Authorization[] {
        return [
            Authorization.ADMIN,
            Authorization.ADS,
            Authorization.AUDIENCE_DATA_SELECTIONS,
            Authorization.AUTHORIZATIONS,
            Authorization.BILLINGS,
            Authorization.CATEGORIES,
            Authorization.CATEGORIZER,
            Authorization.CATEGORIZER_ADMIN,
            Authorization.CMP_CONFIGURATIONS,
            Authorization.CMP_SIRDATA_LIST,
            Authorization.CMP_SIRDATA_LIST_PUBLISH,
            Authorization.CMP_VERSIONS,
            Authorization.CMP_WEBINARS,
            Authorization.CURRENCIES,
            Authorization.CUSTOMER_COUNTINGS,
            Authorization.CUSTOMER_ORDERS,
            Authorization.CUSTOMER_STORAGES,
            Authorization.DATALEAKS,
            Authorization.DEALS,
            Authorization.DEV,
            Authorization.DISTRIBUTIONS,
            Authorization.DISTRIBUTIONS_LICENSES,
            Authorization.HELPER_DOMAINS,
            Authorization.KEYWORDS,
            Authorization.LICENSES,
            Authorization.LOG_AS_PARTNER,
            Authorization.MEDIAS,
            Authorization.MODELING,
            Authorization.NOTIFICATIONS,
            Authorization.ORGANIZATIONS,
            Authorization.PARTNER_DOMAINS,
            Authorization.PARTNERS,
            Authorization.PARTNERS_DELETE,
            Authorization.PORTAL_SETTINGS,
            Authorization.PRICINGS,
            Authorization.REVENUES_IMPORTS,
            Authorization.SALES_REVENUES,
            Authorization.SALES_REVENUES_ADMIN,
            Authorization.SEATS,
            Authorization.SEGMENTS,
            Authorization.SGTM,
            Authorization.TAGS,
            Authorization.USERS
        ];
    }

    get name(): string {
        return `SIRDATA.${this.service}.${this.shortName}`;
    }

    get update(): string {
        return `${this.name}${ApiAuthorization.UPDATE_SUFFIX}`;
    }
}
