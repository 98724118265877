export class ApiService {
    static API_DATA = new ApiService("API_DATA", "Data API");
    static API_PROXY_CATEGORIZER = new ApiService("API_PROXY_CATEGORIZER", "Proxy Categorizer API");
    static ACCOUNT = new ApiService("SIRDATA.ACCOUNT", "Account");
    static ADS = new ApiService("SIRDATA.ADS", "ADS");
    static ANALYTICS_HELPER = new ApiService("SIRDATA.ANALYTICS_HELPER");
    static AUDIENCE = new ApiService("SIRDATA.AUDIENCE", "Audience");
    static AUDIENCES = new ApiService("SIRDATA.AUDIENCES");
    static AUTH = new ApiService("SIRDATA.AUTH", "Auth");
    static CMP = new ApiService("SIRDATA.CMP", "ABconsent");
    static CMP_EXTRA_VENDOR_LIST = new ApiService("SIRDATA.CMP_EXTRA_VENDOR_LIST", "CMP Sirdata List");
    static CONSOLE = new ApiService("SIRDATA.CONSOLE", "Console");
    static CUSTOMER = new ApiService("SIRDATA.CUSTOMER", "Customer");
    static GTM_SERVER_SIDE = new ApiService("SIRDATA.GTM_SERVER_SIDE", "sGTM");
    static HELPER = new ApiService("SIRDATA.HELPER", "Analytics Helper");
    static LIVE_TARGETING = new ApiService("SIRDATA.LIVE_TARGETING", "Live Targeting");
    static PARTNER = new ApiService("SIRDATA.PARTNER");
    static VIDEO_WALL = new ApiService("SIRDATA.VIDEO_WALL", "Video Wall");

    name: string = "";
    label: string = "";

    constructor(name: string, label?: string) {
        this.name = name;
        this.label = label || name;
    }

    static values(): ApiService[] {
        return [
            ApiService.API_DATA,
            ApiService.API_PROXY_CATEGORIZER,
            ApiService.ACCOUNT,
            ApiService.ADS,
            ApiService.ANALYTICS_HELPER,
            ApiService.AUDIENCE,
            ApiService.AUDIENCES,
            ApiService.AUTH,
            ApiService.CMP,
            ApiService.CMP_EXTRA_VENDOR_LIST,
            ApiService.CONSOLE,
            ApiService.CUSTOMER,
            ApiService.GTM_SERVER_SIDE,
            ApiService.HELPER,
            ApiService.LIVE_TARGETING,
            ApiService.PARTNER,
            ApiService.VIDEO_WALL
        ];
    };

    static portals(): ApiService[] {
        return [
            ApiService.ACCOUNT,
            ApiService.AUDIENCE,
            ApiService.CMP,
            ApiService.CONSOLE,
            ApiService.CUSTOMER,
            ApiService.GTM_SERVER_SIDE,
            ApiService.HELPER
        ];
    }

    static forDelegation(): ApiService[] {
        return [
            ApiService.CMP,
            ApiService.GTM_SERVER_SIDE
        ];
    }

    static getByName(name: string): ApiService | undefined {
        return ApiService.values().find((it) => it.name === name);
    }

    static getLabelFromName(name: string): string {
        return ApiService.getByName(name)?.label || "";
    }
}
