import {Rest} from "../../common/api/rest/Rest";
import {PartnerDelegation} from "../model/partner/PartnerDelegation";

export class RestPartnerDelegation extends Rest {
    rootPath = "/console-api/partner";

    list(partnerId: number): Promise<PartnerDelegation[]> {
        return this._client.get(`${this.rootPath}/${partnerId}/delegation`, PartnerDelegation) as Promise<PartnerDelegation[]>;
    }

    upsert(delegation: PartnerDelegation): Promise<PartnerDelegation> {
        return this._client.put(`${this.rootPath}/${delegation.partner_id}/delegation/${delegation.delegated_partner_id}/service/${delegation.service}/access-level/${delegation.access_level}`, undefined, PartnerDelegation) as Promise<PartnerDelegation>;
    }

    delete(delegation: PartnerDelegation) {
        return this._client.delete(`${this.rootPath}/${delegation.partner_id}/delegation/${delegation.delegated_partner_id}/service/${delegation.service}`);
    }
}
