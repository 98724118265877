import {Rest} from "../../common/api/rest/Rest";
import {RevenueDeleteResponse} from "../model/revenue/RevenueDeleteResponse";
import {RevenueImportRequest} from "../model/revenue/import/RevenueImportRequest";
import {RevenueImportResult} from "../model/revenue/import/RevenueImportResult";
import {RevenueImportResponse} from "../model/revenue/import/RevenueImportResponse";
import {RevenueImportSearchQuery} from "../model/revenue/import/RevenueImportSearchQuery";
import {RevenueImportSearchResult} from "../model/revenue/import/RevenueImportSearchResult";
import {RevenuePlatform} from "../model/revenue/RevenuePlatform";
import {RevenueImportJob} from "../model/revenue/import/RevenueImportJob";

export class RestRevenuesImport extends Rest {
    rootPath = "/console-api/revenue";

    list(params: RevenueImportSearchQuery): Promise<RevenueImportSearchResult> {
        return this._client.get(params.owner !== "" ? `${this.rootPath}/import/user/${params.owner}` : `${this.rootPath}/import/user`, RevenueImportSearchResult, {queryParams: params.getJsonParameters()}) as Promise<RevenueImportSearchResult>;
    }

    create(importRequest: RevenueImportRequest): Promise<RevenueImportResponse> {
        return this._client.post(`${this.rootPath}${importRequest.getApiRoute()}`, importRequest.file_buffer, RevenueImportResponse) as Promise<RevenueImportResponse>;
    }

    delete(importId: string): Promise<RevenueDeleteResponse> {
        return this._client.delete(`${this.rootPath}/import/uuid/${importId}/revenue`) as Promise<RevenueDeleteResponse>;
    }

    reupload(importId: string): Promise<RevenueImportResult> {
        return this._client.post(`${this.rootPath}/import/uuid/${importId}/re-upload`, undefined, RevenueImportResult) as Promise<RevenueImportResult>;
    }

    downloadTemplateFile(): Promise<Blob> {
        return this._client.get(`${this.rootPath}/import/template`) as Promise<Blob>;
    }

    getPlatforms(): Promise<RevenuePlatform[]> {
        return this._client.get(`${this.rootPath}/platform`, RevenuePlatform) as Promise<RevenuePlatform[]>;
    }

    getJobStatus(job: RevenueImportJob): Promise<RevenueImportResult> {
        return this._client.get(`${this.rootPath}/${job.platform.destination_name}/job-status/${job.uuid}`, RevenueImportResult) as Promise<RevenueImportResult>;
    }
}
