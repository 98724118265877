import {SearchQuery} from "../../../interface/SearchQuery";
import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";

export enum SalesRevenueSearchParamsField  {
    SEAT_ID = "seat_id",
    PRODUCT_ID = "product_id",
    START_MONTH = "start_month",
    END_MONTH = "end_month"
}

export class SalesRevenueSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    owner_id?: number;
    seat_id?: number;
    organization_id?: number;
    status?: string;
    product_id?: number;
    platform?: string;
    start_month?: string;
    end_month?: string;

    withSearchParams(params: SearchParams): SalesRevenueSearchQuery {
        const newQuery = super.withSearchParams(params) as SalesRevenueSearchQuery;
        newQuery.owner_id = params.getNumber(SearchParamsField.OWNER);
        newQuery.seat_id = params.getNumber(SalesRevenueSearchParamsField.SEAT_ID);
        newQuery.organization_id = params.getNumber(SearchParamsField.ORGANIZATION);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.product_id = params.getNumber(SalesRevenueSearchParamsField.PRODUCT_ID);
        newQuery.platform = params.getString(SearchParamsField.PLATFORM);
        newQuery.start_month = params.getString(SalesRevenueSearchParamsField.START_MONTH);
        newQuery.end_month = params.getString(SalesRevenueSearchParamsField.END_MONTH);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            size: this.size,
            page: this.page,
            sort_by: this.sortBy,
            reverse: this.reverseOrder,
            query: this.query,
            owner_id: this.owner_id,
            seat_id: this.seat_id,
            organization_id: this.organization_id,
            status: this.status,
            product_id: this.product_id,
            platform: this.platform,
            month: this.start_month ? (this.end_month && this.start_month !== this.end_month ? `${this.start_month}:${this.end_month}` : `${this.start_month}`) : ""
        };
    }
}
