import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ApiService} from "../../../api/model/ApiService";
import {Partner} from "../../../api/model/partner/Partner";
import {PartnerDelegation} from "../../../api/model/partner/PartnerDelegation";
import {PartnerDelegationField} from "../../../api/model/partner/PartnerDelegationField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SelectPartner, SelectService} from "../../snippet";

export type ModalAddPartnerDelegationProps = {
    active: boolean;
    partner: Partner;
    onClose: (refresh: boolean) => void;
};

const ModalAddPartnerDelegation: FunctionComponent<ModalAddPartnerDelegationProps> = ({active, partner, onClose}) => {
    const alert = useAlert();
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [delegation, setDelegation] = useState<PartnerDelegation>(new PartnerDelegation());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-partner-delegation";

    useEffect(() => {
        if (active) {
            setDelegation(new PartnerDelegation({[PartnerDelegationField.PARTNER_ID]: partner.id}));
        }
    }, [active, partner]);

    const handleChange = (field: PartnerDelegationField, value: any) => {
        setDelegation((prevState) => new PartnerDelegation({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restPartnerDelegation.upsert(delegation);
            alert.createWithSuccess("delegation");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("delegation", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartners("add_partner_delegation")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textPartners(`field.delegation.${PartnerDelegationField.SERVICE}`)} required>
                                <SelectService
                                    value={delegation.service}
                                    services={ApiService.forDelegation()}
                                    onChange={(service) => handleChange(PartnerDelegationField.SERVICE, service?.name)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textPartners(`field.delegation.${PartnerDelegationField.DELEGATED_PARTNER_ID}`)} required>
                            <SelectPartner
                                value={delegation.delegated_partner_id}
                                service={ApiService.getByName(delegation.service)}
                                excludedPartners={[partner]}
                                onChange={(delegatedPartner) => handleChange(PartnerDelegationField.DELEGATED_PARTNER_ID, delegatedPartner?.id)}
                                disabled={!delegation.service}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddPartnerDelegation;
