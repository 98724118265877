import {Box, BoxRadius, ContentBlock} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

type ChangelogItem = {
    date: string;
    items: string[];
}

const CHANGELOG_ITEMS: ChangelogItem[] = [
    {
        date: "12/02/2025",
        items: [
            "CMP Configurations : Ajout des tags d'installation pour GTM et les CMS",
            "CMP Configurations : Activation par défaut de la special feature 1 à la création d'une CMP",
            "Ticketing : Ajout du champ Communication"
        ]
    },
    {
        date: "15/01/2025",
        items: [
            "Partners : Ajout d'un champ permettant de savoir si un partenaire autorise Sirdata à accéder à ses portails (champ modifiable uniquement par un administrateur)",
            "Partners : Restriction de l'accès aux portails d'un partenaire selon son autorisation",
            "Organizations : Correction d'un bug sur le bouton de sauvegarde"
        ]
    },
    {
        date: "18/12/2024",
        items: [
            "Data Selections : Ajout de la possibilité d'épingler une sélection de Léa sur Audience",
            "sGTM Containers : Désactivation de la possibiltié d'éditer le champ Container hosting",
            "Buyer Seats : Suppression des valeurs par défaut à la création d'un seat",
            "UI/UX : Finalisation de la validation des formulaires sur les modales"
        ]
    }
];

const Changelog: FunctionComponent = () => {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);

    return (
        <ContentBlock header={{title: {label: textHome("changelog.title").toUpperCase()}}} cssClass={"changelog"}>
            <Box radius={BoxRadius.LG} cssClass="changelog__content">
                {CHANGELOG_ITEMS.map((item) =>
                    <div key={item.date} className="changelog__item">
                        <div className="changelog__item__date">{item.date}</div>
                        <ul className="changelog__item__content">
                            {item.items.map((it) =>
                                <li key={it} dangerouslySetInnerHTML={{__html: it}}/>
                            )}
                        </ul>
                    </div>
                )}
            </Box>
        </ContentBlock>
    );
};

export default Changelog;
