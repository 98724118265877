import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CmpVersion} from "../../api/model/cmp/version/CmpVersion";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateCmpVersion from "../../component/modal/cmp-versions/ModalCreateCmpVersion";
import {CmpVersionRow, MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {CmpVersionSearchQuery} from "../../api/model/cmp/version/CmpVersionSearchQuery";
import useSearch from "../../utils/hooks/useSearch";
import {CmpVersionField} from "../../api/model/cmp/version/CmpVersionField";
import useAlert from "../../utils/hooks/useAlert";

function CmpVersions() {
    const {t: textCmpVersions} = useTranslation(TranslationPortalFile.CMP_VERSIONS);
    const [isLoading, setLoading] = useState(true);

    const [cmpVersions, setCmpVersions] = useState<CmpVersion[]>([]);
    const [isShowModalCreateVersion, setShowModalCreateVersion] = useState(false);

    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(CmpVersion, CmpVersionSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                const cmpVersions = await session.restCmpVersion.list();
                setCmpVersions(cmpVersions);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("versions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentCmpVersions = (cmpVersions || []);
        if (search.searchQuery.query) {
            currentCmpVersions = currentCmpVersions.filter(({version}) => `${version}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentCmpVersions);
    }, [cmpVersions, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_VERSIONS}>
                <RestrictedContent allowedTo={Authorization.CMP_VERSIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateVersion(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textCmpVersions("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textCmpVersions(`field.${CmpVersionField.VERSION}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CmpVersionField.VERSION}},
                                        {width: 10, label: textCmpVersions(`field.${CmpVersionField.DATE}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CmpVersionField.DATE}},
                                        {width: 40, label: textCmpVersions(`field.${CmpVersionField.TEXT_FR}`)},
                                        {width: 40, label: textCmpVersions(`field.${CmpVersionField.TEXT_EN}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item: CmpVersion) =>
                                        <CmpVersionRow key={item.version} item={item}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateCmpVersion active={isShowModalCreateVersion} onClose={() => setShowModalCreateVersion(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default CmpVersions;
