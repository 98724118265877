import {ApiModel} from "../../../common/api/model/ApiModel";

export enum CurrencyType {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
    SGD = "SGD",
}

export class Currency extends ApiModel {
    static EUR: Currency = new Currency({id: 1, currency: CurrencyType.EUR, symbol: "€"});
    static USD: Currency = new Currency({id: 2, currency: CurrencyType.USD, symbol: "$"});
    static GBP: Currency = new Currency({id: 3, currency: CurrencyType.GBP, symbol: "£"});
    static SGD: Currency = new Currency({id: 4, currency: CurrencyType.SGD, symbol: "S$"});

    id: number = 0;
    readonly currency: CurrencyType = CurrencyType.EUR;
    readonly symbol: string = "";
    exchange_rate: number = 1;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static fromType(currency: CurrencyType): Currency | undefined {
        return this.values().find((it) => it.currency === currency);
    }

    static formatAmount(value: string, currency: CurrencyType): string {
        if (!value) {
            return "-";
        }
        return `${value} ${this.fromType(currency)?.symbol || currency}`;
    };

    static values(): Currency[] {
        return [
            Currency.EUR,
            Currency.USD,
            Currency.GBP,
            Currency.SGD
        ];
    }

    get label(): string {
        return `${this.currency} (${this.symbol})`;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            currency: this.currency,
            symbol: this.symbol,
            exchange_rate: this.exchange_rate
        };
    }
}
