import {ApiModel} from "../../../common/api/model/ApiModel";
import {PartnerDelegationAccessLevel} from "./PartnerDelegationAccessLevel";

export class PartnerDelegation extends ApiModel {
    partner_id: number = 0;
    delegated_partner_id: number = 0;
    service: string = "";
    access_level: PartnerDelegationAccessLevel = PartnerDelegationAccessLevel.READ_WRITE;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
