import {Rest} from "../../common/api/rest/Rest";
import {CustomerOrder} from "../model/customer/order/CustomerOrder";
import {CustomerOrderSeat} from "../model/customer/order/CustomerOrderSeat";
import {CustomerOrderSearchQuery} from "../model/customer/order/CustomerOrderSearchQuery";
import {CustomerOrderSearchResult} from "../model/customer/order/CustomerOrderSearchResult";
import {CustomerOrderSegmentStats} from "../model/customer/order/CustomerOrderSegmentStats";
import {RestService} from "../interface/RestService";

export class RestCustomerOrder extends Rest implements RestService<CustomerOrder> {
    rootPath = "/console-api/customer/order";

    search(params: CustomerOrderSearchQuery): Promise<CustomerOrderSearchResult> {
        return this._client.get(`${this.rootPath}`, CustomerOrderSearchResult, {queryParams: params.getJsonParameters()}) as Promise<CustomerOrderSearchResult>;
    }

    create(order: CustomerOrder): Promise<CustomerOrder> {
        return new Promise<CustomerOrder>(() => {}); // TODO Implement route
    }

    get(orderId: string): Promise<CustomerOrder> {
        return this._client.get(`${this.rootPath}/${orderId}`, CustomerOrder) as Promise<CustomerOrder>;
    }

    update(order: CustomerOrder): Promise<CustomerOrder> {
        return this._client.put(`${this.rootPath}/${order.id}`, order, CustomerOrder) as Promise<CustomerOrder>;
    }

    delete(orderId: string) {
        // TODO Implement route
    }

    getSegmentStats(orderId: string): Promise<CustomerOrderSegmentStats> {
        return this._client.get(`${this.rootPath}/${orderId}/segment-stats`, CustomerOrderSegmentStats) as Promise<CustomerOrderSegmentStats>;
    }

    export(params: CustomerOrderSearchQuery): Promise<Blob> {
        return this._client.get(`${this.rootPath}/export/csv`, undefined, {queryParams: params.getJsonParameters()}) as Promise<Blob>;
    }

    getSeats(): Promise<CustomerOrderSeat[]> {
        return this._client.get(`${this.rootPath}/seat`, CustomerOrderSeat) as Promise<CustomerOrderSeat[]>;
    }
}
