import {Action, ActionsMenu, Color, IconTooltip, InputText, TableActionColumn, TableColumn, TableColumnStyle, TableRow, ToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../../api/ApiSession";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryLinkExternal} from "../../../../api/model/audience/category/CategoryLinkExternal";
import {CategoryLinkExternalField} from "../../../../api/model/audience/category/CategoryLinkExternalField";
import {Distribution} from "../../../../api/model/audience/distribution/Distribution";
import {RestrictedContent} from "../../../../common/component/widget";
import {LabelStatus} from "../../index";

type CategoryLinkExternalRowProps = {
    link: CategoryLinkExternal;
    onChangeStatus: (link: CategoryLinkExternal) => void;
    onChangeValue: (link: CategoryLinkExternal) => void;
    onRemove: () => void;
};

const CategoryLinkExternalRow: FunctionComponent<CategoryLinkExternalRowProps> = ({link, onChangeStatus, onChangeValue, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [distribution, setDistribution] = useState<Distribution>(new Distribution());

    useEffect(() => {
        (async () => {
            try {
                setDistribution(await session.restDistribution.get(link.map_name));
            } catch (e) {
            }
        })();
    }, [link.map_name]);

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={link.getStatus()}/>
            </TableColumn>
            <TableColumn>
                {distribution.label}
                {(distribution.platform_name && (distribution.platform_name !== distribution.name && distribution.platform_name !== distribution.label)) &&
                    <IconTooltip icon={{name: "help", outlined: true, colorIcon: Color.OCEAN}} text={distribution.platform_name}/>
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputText
                    value={link.value}
                    onChange={(value) => onChangeValue(new CategoryLinkExternal({...link, [CategoryLinkExternalField.VALUE]: value}))}
                    small
                />
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ToggleSwitch
                    checked={link.active}
                    onChange={(value) => onChangeStatus(new CategoryLinkExternal({...link, [CategoryLinkExternalField.ACTIVE]: value}))}
                />
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryLinkExternalRow;
