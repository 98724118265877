import {Rest} from "../../common/api/rest/Rest";
import {Media} from "../model/media/Media";
import {MediaSearchQuery} from "../model/media/MediaSearchQuery";
import {MediaSearchResult} from "../model/media/MediaSearchResult";
import {RestService} from "../interface/RestService";

export class RestMedia extends Rest implements RestService<Media> {
    rootPath = "/console-api/media";

    search(params: MediaSearchQuery): Promise<MediaSearchResult> {
        return this._client.get(`${this.rootPath}`, MediaSearchResult, {queryParams: params.getJsonParameters()}) as Promise<MediaSearchResult>;
    }

    create(mediaCreateRequest: FormData): Promise<Media> {
        return this._client.post(`${this.rootPath}`, mediaCreateRequest, Media) as Promise<Media>;
    }

    get(mediaId: number): Promise<Media> {
        return this._client.get(`${this.rootPath}/${mediaId}`, Media) as Promise<Media>;
    }

    update(media: Media): Promise<Media> {
        return this._client.patch(`${this.rootPath}/${media.id}`, media.getFormData(), Media) as Promise<Media>;
    }

    delete(mediaId: number) {
        return this._client.delete(`${this.rootPath}/${mediaId}`);
    }
}
