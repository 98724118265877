import {
    Action,
    Box,
    BoxProps,
    ContentBlock,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputDomain,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select
} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../api/model/cmp/config/CmpConfigSettings";
import {CMP_CONFIG_STATUSES} from "../../api/model/cmp/config/CmpConfigStatus";
import {CmpConfigVersion} from "../../api/model/cmp/config/CmpConfigVersion";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {detectChanges} from "../../common/utils/portal";
import ModalDuplicateCmpConfiguration from "../../component/modal/cmp-configurations/ModalDuplicateCmpConfiguration";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {CmpConfigurationInstallation, CmpConfigurationSettings, MainContentHeader, MainContentHeaderAction, MainContentHeaderActionsMenu, SelectPartner, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {CmpPreviewHelper} from "../../utils/cmp/CmpPreviewHelper";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {CmpConfigPropertiesField} from "../../api/model/cmp/config/CmpConfigPropertiesField";

function CmpConfigurationsDetails() {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDuplicateCmpConfiguration, setShowModalDuplicateCmpConfiguration] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-cmp-configuration";
    const [config, setConfig] = useState<CmpConfig>(new CmpConfig());
    const [initConfig, setInitConfig] = useState<CmpConfig>(new CmpConfig());

    useEffect(() => {
        (async () => {
            try {
                const config = await session.restCmpConfiguration.get(id);
                setConfig(config);
                setInitConfig(new CmpConfig(config));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("configuration", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(config, initConfig));
    }, [config, initConfig]);

    const handleChange = (field: CmpConfigField, value: any) => {
        setConfig((prevState) => new CmpConfig({...prevState, [field]: value}));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newConfig = await session.restCmpConfiguration.update(config);
            setConfig(newConfig);
            setInitConfig(new CmpConfig(newConfig));
            alert.updateWithSuccess("configuration");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("configuration", e.message);
            }
        }
    };

    const handleSeePartner = () => {
        window.open(Module.PARTNERS.buildRoute(config.id_partner), "_blank");
    };

    const handleFlush = async () => {
        try {
            await session.restCmpConfiguration.flush(config.id);
            alert.actionWithSuccess("cache flushed");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("flush cache", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restCmpConfiguration.delete(config.id);
            navigate(Module.CMP_CONFIGURATIONS.path);
            alert.deleteWithSuccess("CMP configuration");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("CMP configuration", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.CMP_CONFIGURATIONS} element={initConfig.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderAction action={new Action(textCmpConfigurations("actions.flush_configuration"), {name: "cleaning_services"})} onClick={handleFlush}/>
                <MainContentHeaderAction action={new Action(textCmpConfigurations("actions.view_partner"), {name: "person"})} onClick={handleSeePartner}/>
                <MainContentHeaderActionsMenu
                    action={new Action(textCmpConfigurations("actions.preview"), {name: "visibility"})}
                    items={[
                        {label: `${textCmpConfigurations("current_version")} (${initConfig.getConfigVersion().label})`, onClick: () => CmpPreviewHelper.openPreview(config)},
                        ...CmpConfigVersion.values().map((item, index) =>
                            ({label: item.gdprUIVersion.label, onClick: () => CmpPreviewHelper.openPreview(config, item), separator: index === 0}
                            )),
                        ...CmpConfigVersion.values().map((item) =>
                            ({label: item.ccpaUIVersion.label, onClick: () => CmpPreviewHelper.openPreview(config, item, true)}
                            ))
                    ]}
                />
                <RestrictedContent allowedTo={Authorization.CMP_CONFIGURATIONS.update}>
                    <MainContentHeaderAction action={Action.DUPLICATE} onClick={() => setShowModalDuplicateCmpConfiguration(true)} disabled={isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textCmpConfigurations("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={config.status}
                                                        statuses={CMP_CONFIG_STATUSES}
                                                        onChange={(status) => handleChange(CmpConfigField.STATUS, status!.name)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.NAME}`)} required>
                                                    <InputText
                                                        value={config.name}
                                                        onChange={(value) => handleChange(CmpConfigField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.DOMAIN}`)} required>
                                                    <InputDomain
                                                        value={config.domain}
                                                        onChange={(value) => handleChange(CmpConfigField.DOMAIN, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.ID_PARTNER}`)}>
                                                <SelectPartner
                                                    value={config.id_partner}
                                                    onChange={undefined}
                                                    service={ApiService.CMP}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.PROPERTIES}.${CmpConfigPropertiesField.CREATION_TYPE}`)}>
                                                    <Select
                                                        value={config.properties.creation_type}
                                                        options={Object.values(CmpConfigPropertiesCreationType).map((it) => ({
                                                            label: textCmpConfigurations(`${CmpConfigPropertiesField.CREATION_TYPE}.${it}`),
                                                            value: it
                                                        }))}
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                {config.properties.creation_type === CmpConfigPropertiesCreationType.EXTERNAL &&
                                                    <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.PROPERTIES}.${CmpConfigPropertiesField.CREATION_ORIGIN}`)}>
                                                        <InputText
                                                            value={config.properties.creation_origin}
                                                            onChange={() => {}}
                                                            disabled
                                                        />
                                                    </FieldBlock>
                                                }
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </Form>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textCmpConfigurations("section.installation")}}}>
                                <Box>
                                    <CmpConfigurationInstallation cmpConfig={config}/>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutColumns>
                    <ContentBlock header={{title: {label: textCmpConfigurations("section.settings")}}}>
                        <Box>
                            <CmpConfigurationSettings
                                partnerId={config.id_partner}
                                cmpConfig={config}
                                settings={config.settings || new CmpConfigSettings()}
                                onChange={(settings) => handleChange(CmpConfigField.SETTINGS, settings)}
                            />
                        </Box>
                    </ContentBlock>
                </LayoutRows>
                <ModalDuplicateCmpConfiguration
                    active={isShowModalDuplicateCmpConfiguration}
                    source={config}
                    onClose={() => setShowModalDuplicateCmpConfiguration(false)}
                />
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="configuration"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CmpConfigurationsDetails;
