import {routeParamId} from "../common/utils/constants";

export const pathAds = "/ads";

export const pathApiDocumentations = "/api-documentations";

export const pathAudience = "/audience";
export const pathAudienceDataSelections = pathAudience + "/data-selections";

export const pathAuthorizations = "/authorizations";
export const pathAuthorizationsPartner = pathAuthorizations + "/partner";
export const pathAuthorizationsUser = pathAuthorizations + "/user";

export const pathCategories = "/categories";

export const pathCategorizer = "/categorizer";
export const pathCategorizerAdvancedSearch = pathCategorizer + "/advanced-search";

export const pathCmp = "/cmp";
export const pathCmpConfigurations = pathCmp + "/configurations";
export const pathCmpConsentManagementPlatforms = pathCmp + "/consent-management-platforms";
export const pathCmpGlobalVendorList = pathCmp + "/global-vendor-list";
export const pathCmpGoogleACProviderList = pathCmp + "/google-ac-provider-list";
export const pathCmpNetworks = pathCmp + "/networks";
export const pathCmpSirdataList = pathCmp + "/sirdata-list";

export const pathCmpTranslations = pathCmp + "/translations";

export const pathCmpVersions = pathCmp + "/versions";
export const pathCmpWebinars = pathCmp + "/webinars";

export const pathCustomer = "/customer";
export const pathCustomerCountings = pathCustomer + "/countings";
export const pathCustomerOrders = pathCustomer + "/orders";
export const pathCustomerStorages = pathCustomer + "/storages";

export const pathDataleaks = "/dataleaks";
export const pathDataleaksReport = pathDataleaks + "/report" + routeParamId;
export const pathDataleaksQuestions = pathDataleaks + "/questions";
export const pathDataleaksStatistics = pathDataleaks + "/statistics";
export const pathDataleaksVersions = pathDataleaks + "/versions";

export const pathDeals = "/deals";

export const pathDistributions = "/distributions";
export const pathDistributionsLicenses = "/distribution-licenses";

export const pathHelper = "/helper";
export const pathHelperDomains = pathHelper + "/domains";

export const pathKeywords = "/keywords";

export const pathLicenses = "/licenses";

export const pathMedias = "/medias";

export const pathMonthlyRevenues = "/monthly-revenues";

export const pathNotifications = "/notifications";

export const pathOrganizationGroups = "/organization-groups";

export const pathOrganizations = "/organizations";

export const pathPartnerDomains = "/partner/domains";

export const pathPartners = "/partners";
export const pathPartnersConnect = pathPartners + "/connect";

export const pathPortalSettings = "/portal-settings";

export const pathPricings = "/pricings";

export const pathRevenuesImports = "/revenues-imports";

export const pathSeats = "/seats";

export const pathSegments = "/segments";

export const pathSgtm = "/sgtm";
export const pathSgtmContainers = pathSgtm + "/containers";

export const pathTaxonomyTags = "/taxonomy/tags";

export const pathUserProfiles = "/user-profiles";

export const pathUsers = "/users";

/* TRANSLATIONS */

export enum TranslationPortalFile {
    TRANSLATION = "translation",

    ADS_ACCESS = "ads-access",
    API_DOCUMENTATIONS = "api-documentations",
    AUDIENCE_DATA_SELECTIONS = "audience-data-selections",
    AUTHORIZATIONS = "authorizations",
    CATEGORIES = "categories",
    CATEGORIZER = "categorizer",
    CATEGORY_DOMAINS = "category-domains",
    CATEGORY_KEYWORDS = "category-keywords",
    CATEGORY_PANELS = "category-panels",
    CMP_CONFIGURATIONS = "cmp-configurations",
    CMP_LISTS = "cmp-lists",
    CMP_SIRDATA_LIST = "cmp-sirdata-list",
    CMP_TRANSLATIONS = "cmp-translations",
    CMP_VERSIONS = "cmp-versions",
    CMP_WEBINARS = "cmp-webinars",
    CUSTOMER_COUNTINGS = "customer-countings",
    CUSTOMER_ORDERS = "customer-orders",
    CUSTOMER_STORAGES = "customer-storages",
    DATALEAKS_AUDITS = "dataleaks-audits",
    DATALEAKS_AUDITS_REPORT = "dataleaks-audits-report",
    DATALEAKS_QUESTIONS = "dataleaks-questions",
    DATALEAKS_STATISTICS = "dataleaks-statistics",
    DATALEAKS_VERSIONS = "dataleaks-versions",
    DEALS = "deals",
    DISTRIBUTIONS = "distributions",
    DISTRIBUTIONS_LICENSES = "distributions-licenses",
    HELPER_DOMAINS = "helper-domains",
    HOME = "home",
    LICENSES = "licenses",
    LOGIN = "login",
    MEDIAS = "medias",
    MONTHLY_REVENUES = "monthly-revenues",
    NOTIFICATIONS = "notifications",
    ORGANIZATIONS = "organizations",
    PARTNER_DOMAINS = "partner-domains",
    PARTNERS = "partners",
    PRICINGS = "pricings",
    PORTAL_CONTENT = "portal-content",
    PORTAL_SETTINGS = "portal-settings",
    REVENUES_IMPORTS = "revenues-imports",
    SEATS = "seats",
    SEGMENTS = "segments",
    SGTM_CONTAINERS = "sgtm-containers",
    TAXONOMY_TAGS = "taxonomy-tags",
    TICKETING = "ticketing",
    USERS = "users",
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.TRANSLATION,

    TranslationPortalFile.ADS_ACCESS,
    TranslationPortalFile.API_DOCUMENTATIONS,
    TranslationPortalFile.AUDIENCE_DATA_SELECTIONS,
    TranslationPortalFile.AUTHORIZATIONS,
    TranslationPortalFile.CATEGORIES,
    TranslationPortalFile.CATEGORIZER,
    TranslationPortalFile.CATEGORY_DOMAINS,
    TranslationPortalFile.CATEGORY_KEYWORDS,
    TranslationPortalFile.CATEGORY_PANELS,
    TranslationPortalFile.CMP_CONFIGURATIONS,
    TranslationPortalFile.CMP_LISTS,
    TranslationPortalFile.CMP_SIRDATA_LIST,
    TranslationPortalFile.CMP_TRANSLATIONS,
    TranslationPortalFile.CMP_VERSIONS,
    TranslationPortalFile.CMP_WEBINARS,
    TranslationPortalFile.CUSTOMER_COUNTINGS,
    TranslationPortalFile.CUSTOMER_ORDERS,
    TranslationPortalFile.CUSTOMER_STORAGES,
    TranslationPortalFile.DATALEAKS_AUDITS,
    TranslationPortalFile.DATALEAKS_AUDITS_REPORT,
    TranslationPortalFile.DATALEAKS_QUESTIONS,
    TranslationPortalFile.DATALEAKS_STATISTICS,
    TranslationPortalFile.DATALEAKS_VERSIONS,
    TranslationPortalFile.DEALS,
    TranslationPortalFile.DISTRIBUTIONS,
    TranslationPortalFile.DISTRIBUTIONS_LICENSES,
    TranslationPortalFile.HELPER_DOMAINS,
    TranslationPortalFile.HOME,
    TranslationPortalFile.LICENSES,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.MEDIAS,
    TranslationPortalFile.MONTHLY_REVENUES,
    TranslationPortalFile.NOTIFICATIONS,
    TranslationPortalFile.ORGANIZATIONS,
    TranslationPortalFile.PARTNER_DOMAINS,
    TranslationPortalFile.PARTNERS,
    TranslationPortalFile.PRICINGS,
    TranslationPortalFile.PORTAL_CONTENT,
    TranslationPortalFile.PORTAL_SETTINGS,
    TranslationPortalFile.REVENUES_IMPORTS,
    TranslationPortalFile.SEATS,
    TranslationPortalFile.SEGMENTS,
    TranslationPortalFile.SGTM_CONTAINERS,
    TranslationPortalFile.TAXONOMY_TAGS,
    TranslationPortalFile.TICKETING,
    TranslationPortalFile.USERS
];

/* CONSTANTS */
export const PAGE_SIZE = 20;
export const MODAL_PAGE_SIZE = 10;
