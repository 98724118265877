import {Action, AlertSeverity, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {AxiosResponse} from "axios";
import {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {RevenueImport} from "../../api/model/revenue/import/RevenueImport";
import {RevenueImportResult} from "../../api/model/revenue/import/RevenueImportResult";
import {RevenueImportSearchQuery} from "../../api/model/revenue/import/RevenueImportSearchQuery";
import {RevenueImportSearchResult} from "../../api/model/revenue/import/RevenueImportSearchResult";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalImportRevenues from "../../component/modal/revenues-imports/ModalImportRevenues";
import {MainContentHeader, MainContentHeaderAction, RevenueImportRow, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {UserField} from "../../api/model/user/UserField";
import useSearch from "../../utils/hooks/useSearch";
import {RevenueImportField} from "../../api/model/revenue/import/RevenueImportField";
import useAlert from "../../utils/hooks/useAlert";
import {RevenueImportJob} from "../../api/model/revenue/import/RevenueImportJob";

function RevenuesImports() {
    const alert = useAlert();
    const {t: textRevenuesImports} = useTranslation(TranslationPortalFile.REVENUES_IMPORTS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalImportRevenues, setShowModalImportRevenues] = useState(false);
    const [isShowModalConfirm, setShowModalConfirm] = useState(false);
    const [importToDelete, setImportToDelete] = useState(new RevenueImport());
    const [pendingJob, setPendingJob] = useState<RevenueImportJob>();
    const {setSearchResult, ...search} = useSearch(RevenueImport, RevenueImportSearchQuery, RevenueImportSearchResult);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const loadImports = useCallback(async () => {
        try {
            setLoading(true);
            const newSearchResult = await session.restRevenuesImport.list(search.searchQuery);
            setSearchResult(newSearchResult);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("imports", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            await loadImports();
        })();
    }, [loadImports]);

    const checkJobStatus = useCallback(async () => {
        if (!pendingJob) return;
        try {
            const resp = await session.restRevenuesImport.getJobStatus(pendingJob);
            if (!!resp.count_deleted || !!resp.count_imported) {
                setPendingJob(undefined);
                if (!!resp.count_deleted) {
                    alert.deleteWithSuccess(`${resp.count_deleted} revenues`);
                }
                if (!!resp.count_imported) {
                    alert.push(textRevenuesImports("message.import_success", {count: resp.count_imported, total: resp.total}), AlertSeverity.SUCCESS);
                }
                await loadImports();
            } else {
                alert.push(textRevenuesImports("message.import_in_progress"), AlertSeverity.INFO);
                timeoutRef.current = setTimeout(() => checkJobStatus(), 15000);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    const response = e.response as AxiosResponse<RevenueImportResult>;
                    alert.push({title: textRevenuesImports("message.import_failed"), text: response.data.error}, AlertSeverity.DANGER);
                } else {
                    alert.failToLoad("job status", e.message);
                }
            }
        }
    }, [alert, pendingJob, loadImports, textRevenuesImports]);

    useEffect(() => {
        if (!pendingJob) return;
        (async () => {
            await checkJobStatus();
        })();
        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, [pendingJob, checkJobStatus]);

    const handleDelete = (revenueImport: RevenueImport) => {
        setImportToDelete(revenueImport);
        setShowModalConfirm(true);
    };

    const handleConfirmDelete = async (revenueImport: RevenueImport) => {
        setShowModalConfirm(false);
        try {
            const resp = await session.restRevenuesImport.delete(revenueImport.uuid);
            alert.push(textRevenuesImports("import.message.delete_success", {count: resp.count}), AlertSeverity.SUCCESS);
            await loadImports();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("revenues", e.message);
            }
        } finally {
            setImportToDelete(new RevenueImport());
        }
    };

    const handleUpload = async (revenueImport: RevenueImport) => {
        try {
            const resp = await session.restRevenuesImport.reupload(revenueImport.uuid);
            alert.push(textRevenuesImports("import.message.reimport_success", {count: resp.count_imported}), AlertSeverity.SUCCESS);
            await loadImports();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("reimport revenues", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.REVENUES_IMPORTS}>
                <RestrictedContent allowedTo={Authorization.REVENUES_IMPORTS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalImportRevenues(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar>
                            <SearchField label={textRevenuesImports(`import.field.${RevenueImportField.USER}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner}
                                    field={UserField.EMAIL}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.email)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textRevenuesImports(`import.field.${RevenueImportField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textRevenuesImports(`import.field.${RevenueImportField.PLATFORM_ID}`)},
                                        {width: 10, label: textRevenuesImports(`import.field.${RevenueImportField.USER}`)},
                                        {width: 15, label: textRevenuesImports(`import.field.${RevenueImportField.NAME}`)},
                                        {width: 5, label: textRevenuesImports(`import.field.${RevenueImportField.COUNT_ROW_IMPORTED}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textRevenuesImports(`import.field.${RevenueImportField.TOTAL_REVENUE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textRevenuesImports(`import.field.${RevenueImportField.DATE_TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textRevenuesImports("import.field.period")},
                                        {width: 10, label: textRevenuesImports("import.field.creation_update")},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((revenueImport: RevenueImport) =>
                                        <RevenueImportRow
                                            key={revenueImport.uuid}
                                            revenueImport={revenueImport}
                                            onDelete={() => handleDelete(revenueImport)}
                                            onUpload={() => handleUpload(revenueImport)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalImportRevenues
                    active={isShowModalImportRevenues}
                    onClose={(job) => {
                        setShowModalImportRevenues(false);
                        setPendingJob(job);
                    }}
                />
                <ModalConfirmDelete
                    active={isShowModalConfirm}
                    entity={`import "${importToDelete.name}"`}
                    confirm={() => handleConfirmDelete(importToDelete)}
                    cancel={() => {
                        setImportToDelete(new RevenueImport());
                        setShowModalConfirm(false);
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default RevenuesImports;
