enum values {
    DATA = "DATA",
    DEBOARDING = "DEBOARDING",
    MEDIA = "MEDIA"
}

export class SalesRevenueCostType {
    static DATA = values.DATA;
    static DEBOARDING = values.DEBOARDING;
    static MEDIA = values.MEDIA;

    static values(): string[] {
        return Object.values(values);
    };
}