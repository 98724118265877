import {Alert, AlertSeverity, CodeBlock, CodeBlockLanguage, FlexContent, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, SvgAmp, Tabs} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigIntegrationType} from "../../../api/model/cmp/config/CmpConfigIntegrationType";

type CmpConfigurationInstallationProps = {
    cmpConfig: CmpConfig;
};

const CmpConfigurationInstallation: FunctionComponent<CmpConfigurationInstallationProps> = ({cmpConfig}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <Tabs
            headers={[
                {label: textCmpConfigurations(`installation.${CmpConfigIntegrationType.WEB}.tab`), icon: {name: "web"}},
                {label: textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.tab`), icon: {name: "dns"}},
                {label: textCmpConfigurations(`installation.${CmpConfigIntegrationType.CMS}.tab`), icon: {name: "dashboard_customize"}},
                {label: textCmpConfigurations(`installation.${CmpConfigIntegrationType.AMP}.tab`), icon: <SvgAmp/>}
            ]}
        >
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                {cmpConfig.settings.external.googleAnalytics ?
                    <Alert
                        text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.WEB}.google_analytics_active`)}
                        severity={AlertSeverity.SUCCESS}
                    /> :
                    <Alert
                        text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.WEB}.google_analytics_inactive`)}
                        severity={AlertSeverity.DANGER}
                    />
                }
                <CodeBlock
                    header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.WEB}.tab`)}
                    language={CodeBlockLanguage.HTML}
                    code={cmpConfig.getWebScript()}
                    copiable
                    wrapLongLines
                />
            </FlexContent>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                {cmpConfig.settings.external.googleAnalytics ? (
                    <Alert
                        text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.google_analytics_active`)}
                        severity={AlertSeverity.DANGER}
                    />
                ) : (
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                        <Alert
                            text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.google_analytics_inactive_warning`)}
                            severity={AlertSeverity.WARNING}
                        />
                        <Alert
                            text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.google_analytics_inactive_success`)}
                            severity={AlertSeverity.SUCCESS}
                        />
                    </FlexContent>
                )}
                <FlexContent
                    direction={FlexContentDirection.ROW}
                    spacing={FlexContentSpacing.MEDIUM}
                    mobileDirection={FlexContentMobileDirection.COLUMN}
                >
                    <CodeBlock
                        header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.partner_id`)}
                        language={CodeBlockLanguage.MARKDOWN}
                        code={cmpConfig.id_partner.toString()}
                        copiable
                    />
                    <CodeBlock
                        header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.GTM}.configuration_id`)}
                        language={CodeBlockLanguage.MARKDOWN}
                        code={cmpConfig.id}
                        copiable
                    />
                </FlexContent>
            </FlexContent>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                {cmpConfig.settings.external.googleAnalytics ?
                    <Alert
                        text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.CMS}.google_analytics_active`)}
                        severity={AlertSeverity.SUCCESS}
                    /> :
                    <Alert
                        text={textCmpConfigurations(`installation.${CmpConfigIntegrationType.CMS}.google_analytics_inactive`)}
                        severity={AlertSeverity.DANGER}
                    />
                }
                <FlexContent
                    direction={FlexContentDirection.ROW}
                    spacing={FlexContentSpacing.MEDIUM}
                    mobileDirection={FlexContentMobileDirection.COLUMN}
                >
                    <CodeBlock
                        header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.CMS}.partner_id`)}
                        language={CodeBlockLanguage.MARKDOWN}
                        code={cmpConfig.id_partner.toString()}
                        copiable
                    />
                    <CodeBlock
                        header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.CMS}.configuration_id`)}
                        language={CodeBlockLanguage.MARKDOWN}
                        code={cmpConfig.id}
                        copiable
                    />
                </FlexContent>
            </FlexContent>
            <CodeBlock
                header={textCmpConfigurations(`installation.${CmpConfigIntegrationType.AMP}.tab`)}
                language={CodeBlockLanguage.HTML}
                code={cmpConfig.getAmpScript()}
                copiable
                wrapLongLines
            />
        </Tabs>
    );
};

export default CmpConfigurationInstallation;
