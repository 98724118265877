import {
    Action,
    Alert,
    AlertSeverity,
    Button,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    SelectAutocomplete,
    Textarea,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {JiraIssue} from "../../api/model/ticketing/JiraIssue";
import {JiraIssueKey} from "../../api/model/ticketing/JiraIssueKey";
import {JiraIssueField} from "../../api/model/ticketing/JiraIssueField";
import {JiraPriority} from "../../api/model/ticketing/JiraPriority";
import {JiraProject} from "../../api/model/ticketing/JiraProject";
import {JiraScope} from "../../api/model/ticketing/JiraScope";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {JiraIssueScope} from "../../api/model/ticketing/JiraIssueScope";
import {JiraIssueScopeField} from "../../api/model/ticketing/JiraIssueScopeField";

const ModalTicketing: FunctionComponent = () => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textTicketing} = useTranslation(TranslationPortalFile.TICKETING);
    const alert = useAlert();
    const [isActive, setActive] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const FORM_ID = "form-create-jira-issue";
    const [project, setProject] = useState<JiraProject>(new JiraProject());
    const [priorities, setPriorities] = useState<JiraPriority[]>([]);
    const [currentMainScope, setCurrentMainScope] = useState<JiraScope>();
    const [issue, setIssue] = useState<JiraIssue>(new JiraIssue());
    const [issueKey, setIssueKey] = useState<JiraIssueKey>();

    useEffect(() => {
        if (!isActive) {
            return;
        }

        (async () => {
            try {
                const account = await session.getAccount();
                const currentUser = await session.getUserById(account.id);
                if (!currentUser?.properties.jira_id) {
                    alert.push({text: textTicketing("message.missing_jira_id"), severity: AlertSeverity.DANGER});
                    setActive(false);
                    return;
                }

                setIssue(new JiraIssue({
                    [JiraIssueField.PROJECT_ID]: JiraProject.FRONT_PROJECT_ID,
                    [JiraIssueField.REPORTER_ID]: currentUser?.properties.jira_id
                }));

                setProject(await session.restJira.getProject(JiraProject.FRONT_PROJECT_ID));
                setPriorities(await session.restJira.getPriorities());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("form", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [isActive, alert, textTicketing]);

    useEffect(() => {
        UIEventManager.addListener(ModalTicketingUiEvent, async () => {
            setActive(true);
            setCurrentMainScope(undefined);
            setIssueKey(undefined);

            return () => UIEventManager.removeAllListeners(ModalTicketingUiEvent);
        });
    }, [alert]);

    const handleChange = (field: JiraIssueField, value: any) => {
        setIssue((prevState) => new JiraIssue({...prevState, [field]: value}));
    };

    const handleChangeIssueTypeId = (issueTypeId: string) => {
        handleChange(JiraIssueField.ISSUETYPE_ID, issueTypeId);
        handleChange(JiraIssueField.SCOPE, undefined);
        setCurrentMainScope(undefined);
    };

    const handleChangeScope = (scopeId?: string, childScopeId?: string) => {
        const scope = new JiraIssueScope({[JiraIssueScopeField.ID]: scopeId});
        if (childScopeId) {
            scope.child = new JiraIssueScope({[JiraIssueScopeField.ID]: childScopeId});
        }
        handleChange(JiraIssueField.SCOPE, scope);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setIssueKey(await session.restJira.create(issue));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("ticket", e.message);
            }
        }
    };

    const handleNewTicket = () => {
        setActive(false);
        setTimeout(() => UIEventManager.emit(ModalTicketingUiEvent), 1);
    };

    return (
        <>
            <ModalNew onClose={() => setActive(false)} active={isActive && !issueKey} loading={isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle title={textTicketing("title")}/>
                </ModalHeader>
                <ModalContent>
                    <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows>
                            <FormLayoutColumns>
                                <FieldBlock
                                    label={textTicketing(`field.${JiraIssueField.PROJECT_ID}`)}
                                    required
                                >
                                    <Select
                                        value={issue.project_id}
                                        placeholder={textTicketing(`placeholder.${JiraIssueField.PROJECT_ID}`)}
                                        options={[{label: project.name, value: project.id}]}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textTicketing(`field.${JiraIssueField.ISSUETYPE_ID}`)}
                                    required
                                >
                                    <Select
                                        value={issue.issuetype_id}
                                        placeholder={textTicketing(`placeholder.${JiraIssueField.ISSUETYPE_ID}`)}
                                        options={(project.issue_types || []).map((type) => ({label: type.name, value: type.id}))}
                                        onChange={(option) => handleChangeIssueTypeId(option?.value as string)}
                                        disabled={!issue.project_id}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FormLayoutColumns>
                                <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                    <FieldBlock
                                        label={textTicketing(`field.${JiraIssueField.SCOPE}`)}
                                        required
                                    >
                                        <SelectAutocomplete
                                            value={issue.scope?.id}
                                            placeholder={textTicketing(`placeholder.${JiraIssueField.SCOPE}`)}
                                            options={(project.issue_types.find((type) => type.id === issue.issuetype_id)?.scopes || []).map((scope) => ({label: scope.name, value: scope.id, scope: scope}))}
                                            onChange={(option) => {
                                                handleChangeScope(option?.scope?.id);
                                                setCurrentMainScope(option?.scope);
                                            }}
                                            disabled={!issue.issuetype_id}
                                        />
                                    </FieldBlock>
                                    {!!currentMainScope?.items?.length &&
                                        <FieldBlock>
                                            <SelectAutocomplete
                                                value={issue.scope?.child?.id}
                                                options={(currentMainScope?.items || []).map((scope) => ({label: scope.name, value: scope.id, scope: scope}))}
                                                onChange={(option) => handleChangeScope(issue.scope?.id, option?.scope?.id)}
                                                disabled={!currentMainScope?.items}
                                                clearable
                                            />
                                        </FieldBlock>
                                    }
                                </FormLayoutRows>
                                <FieldBlock
                                    label={textTicketing(`field.${JiraIssueField.PRIORITY_ID}`)}
                                    required
                                >
                                    <Select
                                        value={issue.priority_id}
                                        placeholder={textTicketing(`placeholder.${JiraIssueField.PRIORITY_ID}`)}
                                        options={priorities.map((priority) => ({label: priority.name, value: priority.id}))}
                                        onChange={(option) => handleChange(JiraIssueField.PRIORITY_ID, option?.value)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FormLayoutColumns>
                                <FieldBlock label={textTicketing(`field.${JiraIssueField.BUSINESS_IMPACT_ID}`)}>
                                    <Select
                                        value={issue.business_impact_id}
                                        placeholder={textTicketing(`placeholder.${JiraIssueField.BUSINESS_IMPACT_ID}`)}
                                        options={(project.issue_types.find((type) => type.id === issue.issuetype_id)?.business_impacts || []).map((it) => ({label: it.name, value: it.id}))}
                                        onChange={(option) => handleChange(JiraIssueField.BUSINESS_IMPACT_ID, option?.value)}
                                        disabled={!issue.issuetype_id}
                                        clearable
                                    />
                                </FieldBlock>
                                <FieldBlock
                                    label={textTicketing(`field.${JiraIssueField.COMMUNICATION_ID}`)}
                                    tooltip={textTicketing(`tooltip.${JiraIssueField.COMMUNICATION_ID}`)}
                                    required
                                >
                                    <Select
                                        value={issue.communication_id}
                                        options={(project.issue_types.find((type) => type.id === issue.issuetype_id)?.communications || []).map((it) => ({label: it.name, value: it.id}))}
                                        onChange={(option) => handleChange(JiraIssueField.COMMUNICATION_ID, option?.value)}
                                        disabled={!issue.issuetype_id}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                            <FieldBlock
                                label={textTicketing(`field.${JiraIssueField.SUMMARY}`)}
                                required
                            >
                                <InputText
                                    value={issue.summary}
                                    placeholder={textTicketing(`placeholder.${JiraIssueField.SUMMARY}`)}
                                    onChange={(value) => handleChange(JiraIssueField.SUMMARY, value)}
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textTicketing(`field.${JiraIssueField.DESCRIPTION}`)}
                                required
                            >
                                <Textarea
                                    value={issue.description}
                                    placeholder={textTicketing(`placeholder.${JiraIssueField.DESCRIPTION}`)}
                                    onChange={(value) => handleChange(JiraIssueField.DESCRIPTION, value)}
                                    rows={5}
                                    isExpandable
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                    </Form>
                </ModalContent>
                <ModalActions>
                    <ButtonLinkCancel onClick={() => setActive(false)}/>
                    <Button form={FORM_ID} size={ButtonSize.BIG}>
                        {textCommon(Action.SEND.labelKey)}
                    </Button>
                </ModalActions>
            </ModalNew>
            <ModalNew active={isActive && !!issueKey}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: textTicketing("message.issue_created", {
                                        project: project.name,
                                        key: issueKey?.key
                                    })
                                }}
                            />
                        </ModalDescription>
                        <Alert text={textTicketing("message.issue_created_warning")} severity={AlertSeverity.WARNING}/>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.MEDIUM} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={handleNewTicket}>
                                {textTicketing("create_new_ticket")}
                            </Button>
                            <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => setActive(false)}>
                                {textCommon(Action.CLOSE.labelKey)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalTicketing;
export const ModalTicketingUiEvent = "ModalTicketing";
